import React, { useEffect, useState } from 'react'
import { links } from './links'
import { useLocation, useNavigate } from 'react-router-dom'
import Register from '../../../pages/authentification/Register'
import RegisterModal from '../../modal/RegisterModal'
import MainCard from '../../Menu/MainCard'
import { useSelector } from 'react-redux'
import { useGetAllAnnouncementsQuery } from '../../../services/announcementApi'
import ListCategories from '../categories/ListCategories'
import { BASE_URL } from '../../../helpers/Root'
import { MdAttachMoney } from 'react-icons/md'

function MainBar({ fixedCall, categories }) {
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const navigate = useNavigate()
  const [name, setName] = useState('null')
  const [openMenu, setOpenMenu] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  const location = useLocation()
  const currentPath = location.pathname

  const checkCategory = useSelector(
    (state) => state?.opencategories?.opencategories
  )

  const shop = useSelector((state) => state.user.myShop)

  const [category, setCategory] = useState(null)
  const [nameOption, setNameOption] = useState(null)

  useEffect(() => {
    setNameOption(nameOption)
  }, [])

  const [closeCategory, setCloseCategory] = useState(false)

  const { data: announcements } = useGetAllAnnouncementsQuery()

  let data = null

  if (category) {
    data = announcements?.filter(
      (announcement) => announcement?.category._id === category?._id
    )
  }

  let seg = null
  const getBasePath = (path) => {
    const url = new URL(path, BASE_URL)
    let segments = url.pathname.split('/').filter(Boolean)
    seg = segments

    // Vérifier si le dernier segment est un identifiant Mongoose
    const lastSegment = segments[segments.length - 1]
    const isMongooseId = /^[0-9a-fA-F]{24}$/.test(lastSegment)

    // Si c'est un identifiant Mongoose, le supprimer
    if (isMongooseId) {
      segments = segments.slice(0, -1)
    }

    if (segments.length < 1) {
      return url.pathname
    } else if (segments.length < 2) {
      return `/${segments[0]}`
    } else if (segments.length > 1) {
      return `/${segments[0]}/${segments[1]}`
    }
  }

  const basePath =
    getBasePath(currentPath) === '/shop/me'
      ? '/announcements/me'
      : getBasePath(currentPath)

  return (
    <div className="relative">
      {/* <div className="flex px-[16px] lg:px-16  justify-between items-center shadow-md bg-primary text-white z-50 left-0 right-0 md:py-3 h-16"> */}
      <div
        className={`flex px-[16px] md:px-10 lg:px-16  justify-between items-center shadow-md ${!fixedCall ? 'bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700 text-white  ' : 'bg-white text-[rgba(0,0,0,0.83)]'}   md:py-3 h-16`}
      >
        {links(
          setOpenMenu,
          openMenu,
          setConnecting,
          setName,
          currentUser,
          navigate,
          setCategory,
          setCloseCategory,
          shop
        ).map((link, index) => {
          return (
            <div
              key={index}
              className=""
              onMouseEnter={() => {
                if (link.isHover) {
                  link.action()
                }
              }}
              onMouseLeave={() => {
                if (link.isHover) {
                  link.action()
                }
              }}
              onClick={() => {
                if (!link.isHover) {
                  link.action()
                }
              }}
            >
              <div className="flex  lg:flex-row py-[13px] md:py-[21px] lg:py-[21px] gap-2 items-center flex-col sm:gap-1 cursor-pointer">
                <span className="md:text-[15px] sm:text-[10px] flex justify-center items-center gap-2 relative">
                  {}
                  {link?.name == 'sell' ? (
                    <div
                      className="px-[7.5px] lg:px-3 rounded-full lg:py-2 py-[7.5px] text-blue-700 bg-white outline 
                          outline-white outline-2 outline-offset-2 lg:outline-none lg:text-blue-700
                          lg:rounded-md lg:hover:bg-[rgba(255,255,255,0.74)] lg:bg-[rgba(255,255,255,0.86)]"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <MdAttachMoney className="block md:hidden text-[15px]" />
                        <span className="block md:hidden">Vendre</span>
                      </div>
                      <span className="hidden md:block">Vendre un produit</span>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center lg:flex-row lg:gap-2">
                      <span className="text-lg md:text-xl">{link.icon}</span>
                      <span className="text-[11px] pt-1 md:pt-0 font-normal md:font-semibold lg:font-semibold md:text-base">
                        {link.name}
                      </span>
                    </div>
                  )}
                  {basePath === link.ref && (
                    <div
                      className={`absolute ${seg?.length > 2 && 'lg:-bottom-[12px]'}  left-0 right-0 h-[3px] ${!fixedCall ? 'bg-white' : 'bg-[rgba(0,0,0,0.83)]'} -bottom-[12px] lg:-bottom-[20px]`}
                    ></div>
                  )}
                </span>
              </div>

              {checkCategory && link?.isCateories && (
                <ListCategories
                  categories={categories}
                  filtercannouncements={data}
                  setCategory={setCategory}
                  category={category}
                  openMenu={openMenu}
                  name={name}
                  closeCategory={closeCategory}
                  setCloseCategory={setCloseCategory}
                />
              )}
            </div>
          )
        })}
      </div>
      {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )}
      {openMenu && (
        <MainCard setOpenMenu={setOpenMenu} setConnecting={setConnecting} />
      )}
    </div>
  )
}

export default MainBar
