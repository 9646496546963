import React, { useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IoEyeOutline } from 'react-icons/io5'
import { FiUser } from 'react-icons/fi'
import { BASE_URL } from '../../helpers/Root'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../features/user/user'
import { setToast } from '../../features/toast/toast'
import ReactGA from 'react-ga4'

function Login({ setRegistering, setOpenModal }) {
  const navigate = useNavigate()
  const [values, setValues] = useState({ info: '', password: '' })
  const [loading, setLoading] = useState(false)
  const [isHide, setIsHide] = useState(false)
  let dispatch = useDispatch()

  dispatch(setCurrentUser(null))
  dispatch(setToast(false))

  const connexion = 'Connectez-vous'

  localStorage.removeItem('chat')

  const hidePassword = () => {
    if (isHide) {
      setIsHide(false)
    } else {
      setIsHide(true)
    }
  }

  const toastOptions = {
    position: 'bottom-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const validateForm = () => {
    const { info, password } = values
    if (info === '') {
      toast.error(
        "Le nom d'utilsateur, le téléphone ou l'Email est obligatoire",
        toastOptions
      )
      setLoading(false)
      return false
    } else if (password === '') {
      toast.error('Le mot de passe est obligatoire', toastOptions)
      setLoading(false)
      return false
    }
    return true
  }
  //https://uty-ti30.onrender.com
  const handleSubmit = async (event) => {
    event.preventDefault()
    ReactGA.event({
      category: 'Main clicks',
      action: 'Connect by password',
    })
    setLoading(true)
    if (validateForm()) {
      const { info, password } = values
      console.log(values)
      await axios
        .post(`${BASE_URL}/api/auth/login`, {
          info,
          password,
        })
        .then((response) => {
          if (response.data.status === true) {
            console.log('Success user: ', response.data)

            let user = response.data.user

            // useEffect(() => {
            dispatch(setCurrentUser(response.data.user))
            dispatch(setToast(true))
            localStorage.setItem(
              'currentUser',
              JSON.stringify(response.data.user)
            )
            if (response?.data?.user?.isAdmin) {
              navigate(
                `/Account/list-users?username_admin=${user?.username}&isAdmin=${user?.isAdmin}`
              )
            } else {
              if (response.data.seller !== null) {
                localStorage.setItem(
                  'seller',
                  JSON.stringify(response.data.seller)
                )
              }
              navigate('/')
              setLoading(false)
              window.location.reload()
            }
          } else {
            toast.error(response.data.msg, toastOptions)
            setLoading(false)
          }
        })
        .catch((error) => {
          toast.error(error, toastOptions)
          setLoading(false)
        })
    }
  }
  return (
    <div>
      <div className="rounded-lg items-center">
        <form action="" onSubmit={(event) => handleSubmit(event)}>
          <div className="relative flex flex-col gap-2">
            <div className="text-[13px] text-[rgba(0,0,0,0.76)]">
              {"Nom d'utilisateur, téléphone ou Email"}
            </div>
            <div className="md:bg-white rounded-[0.5rem]">
              <input
                type="text"
                id="info"
                className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[6px] rounded-[2px]"
                placeholder="nom d'utilisateur, telephone ou Email"
                name="info"
                onChange={(e) => handleChange(e)}
              />
              <FiUser className="absolute text-primary top-[47px] right-0 text-[12px] md:top-[44px] mr-2" />
            </div>
            <div className="text-[13px] text-[rgba(0,0,0,0.76)] mt-3">
              {'Mot de passe'}
            </div>
            <div className="relative flex md:bg-white rounded-[0.5rem]">
              <input
                className="border-[1px] pl-2 pr-6 border-[rgba(90,86,86,0.08)] py-[10px] w-full outline-none text-[15px] text-[rgba(0,0,0,0.67)] md:py-[6px] rounded-[2px]"
                type={isHide ? 'text' : 'password'}
                placeholder="Mot de passe"
                name="password"
                onChange={(e) => handleChange(e)}
              />
              <IoEyeOutline
                className="absolute text-primary top-[13px] right-0 text-[12px] md:top-[14px] mr-2"
                onClick={hidePassword}
              />
            </div>
            <div className=" py-[10px] rounded-[0.5rem] text-white text-[15px] bg-blue-500 text-center md:py-[7px]">
              <button className="" type="submit">
                {loading ? (
                  <div className="">Vérification...</div>
                ) : (
                  <div className="">{connexion}</div>
                )}
              </button>
            </div>
            <Link
              to="/auth/forget-password"
              className="text-end text-blue-600 text-[13px] hover:underline"
            >
              Mot de passe oublié ?
            </Link>
            <div className="md:mt-0 text-[15px] text-center">
              <div className="md:mt-2 text-[15px] text-center mb-2">
                <p>
                  <span
                    onClick={() => {
                      setOpenModal(false)
                      setRegistering(true)
                    }}
                    className="text-primary mr-1 cursor-pointer underline"
                  >
                    Créer un compte
                  </span>
                  <span className="text-[#363434]">si vous en avez pas</span>
                </p>
              </div>
            </div>
          </div>
        </form>
        <div className="flex justify-center items-center p-2 -mt-3">ou</div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Login
