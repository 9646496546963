import TopBar from './nav-bar/TopBar'
import MainBar from './nav-bar/MainBar'
import HomeHeader from '../HomeHeader'
import SocialsUty from './nav-bar/SocialsUty'

function NavHome({ infoUserRef, fixedCall, categories }) {
  return (
    <div>
      <div className="hidden lg:block">
        {fixedCall ? (
          <div ref={infoUserRef} className={fixedCall}>
            <div className="fixed top-0 left-0 right-0 z-50 ">
              <SocialsUty />
              <MainBar fixedCall={fixedCall} categories={categories} />
            </div>
            <HomeHeader />
          </div>
        ) : (
          <div ref={infoUserRef}>
            <div className={`fixed top-0 left-0 right-0 z-50 `}>
              <TopBar />
              <MainBar fixedCall={fixedCall} categories={categories} />
            </div>
            <HomeHeader />
          </div>
        )}
      </div>
      <div ref={infoUserRef} className="block lg:hidden">
        <div className={`fixed top-0 left-0 right-0 z-50 `}>
          <TopBar />
          <MainBar
            categories={categories}
            // fixedCall={fixedCall}
          />
        </div>
        <HomeHeader />
      </div>
    </div>
  )
}

export default NavHome

// <nav ref={infoUserRef}>
//   <div
//     className={`transition-all duration-100 ease-out delay-100 ${fixedCall ? 'opacity-0' : 'opacity-100'}`}
//   >
//     <TopBar fixedCall={fixedCall} />
//     <MainBar fixedCall={fixedCall} />
//   </div>
//   <div
//     className={`fixed top-0 left-0 right-0 z-50 gap-1 transition-all duration-200 ease-out delay-200 ${fixedCall ? 'opacity-100' : 'opacity-0'}`}
//   >
//     <MainBar fixedCall={fixedCall} />
//   </div>
//   <div>
//     <HomeHeader fixedCall={fixedCall} />
//   </div>
// </nav>
