import React, { useRef, useState } from 'react'
import Sidebar from '../components/compte/Sidebar'
import { Routes, Route } from 'react-router-dom'
import WantedList from './Enquiries/WantedList'
import ProposalForm from '../pages/proposals/Create'
import Proposals from '../pages/proposals'
import Profil from '../assets/profil.jpg'

import { IoMenu } from 'react-icons/io5'
import SubscribeSeller from './subcribes/SubscribeSeller'
import Offers from './Enquiries/Offers'
import OfferDetail from './Enquiries/OfferDetail'
// import UserInfos from '../components/compte/UserInfos'
import Chat from './chats'
import { useSelector } from 'react-redux'
// import Cart from './Cart'
import ShowCategories from './admin/categories/categories'
import CreateCategory from './admin/categories/create-category'
import UpdateCategory from './admin/categories/update-category'
import CreateSubCategory from './admin/subcategories/create-subcategory'
import ShowSubCategories from './admin/subcategories/subcategories'
import UpdateSubCategory from './admin/subcategories/update-subcategory'
import JoinSubCategoryToCategory from './admin/categories/join-category-tosubcategory'
import DetailCatgory from './admin/categories/detail-category'
import ShowUsers from './admin/users/show-users'
import DetailUser from './admin/users/detail-user'
import ListDevises from './admin/currencies'
import CreateCurrency from './admin/currencies/create-currency'
import CreateMeansTransport from './admin/meanstransports/create-meanstransport'
import ListMeansTransports from './admin/meanstransports'
import EditMeansTransport from './admin/meanstransports/update-meansport'
import ListDeliverAccepted from './admin/delivers'
import ListTrackers from './admin/tracks'
import CreateProductBrand from './admin/productBrands/add-product-brands'
import ListProductType from './admin/productTypes'
import CreateProductType from './admin/productTypes/add-product-types'
import ListProductBrand from './admin/productBrands/index'
// import ListProductType from './admin/productTypes'

export default function UserDashboard() {
  const windowWidth = useRef(window.innerWidth).current
  const currentUser = useSelector((state) => state.user.currentUser)

  //State
  const [active, setActive] = useState(false)
  const handleActiveSidebar = () => {
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  return (
    <div className="relative z-10 flex flex-col">
      <div className="h-[100vh] flex">
        {windowWidth < 768 ? (
          <div className="fixed z-20 text-[black] px-3 py-2 bg-[white] font-bold text-2xl flex left-0 right-0 top-0">
            {active ? (
              ''
            ) : (
              <div className="flex items-center justify-between w-screen">
                <div className="flex flex-row items-center">
                  <IoMenu
                    className="text-[35px] "
                    onClick={handleActiveSidebar}
                  />
                  <div className="text-[18px] font-light pl-3 pt-1">
                    Mon espace
                  </div>
                </div>
                <div className="h-[35px] w-[35px] bg-red-400 rounded-full">
                  <img
                    src={currentUser?.image ? currentUser?.image : Profil}
                    alt=""
                    className="rounded-full h-[35px] w-[35px] object-cover"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div
          className={
            active
              ? 'transition-all delay-150 ease-out z-50 duration-300 w-[90%] bg-gray-100 md:w-[25%] absolute shadow-bth top-0 bottom-0 left-0 md:static'
              : 'transition-all delay-150 ease-out z-50 duration-300 w-[90%] md:w-[25%] bg-gray-100 absolute top-0 bottom-0 left-[-348px] md:static'
          }
        >
          <Sidebar active={active} setActive={setActive} />
        </div>
        <div
          className={
            active
              ? 'bg-gray-100 h-auto bg-red md:w-[80%] hidden'
              : ' bg-gray-100 md:bg-slate-100 h-auto md:w-[100%]'
          }
        >
          <div className="flex md:h-[100vh] md:bg-gray-200 justify-center items-center mt-[0px] md:mt-0">
            <div
              className={
                active
                  ? 'filter-data md:w-[85%] h-[auto] bg-white   md:bg-white ml-52 md:p-10'
                  : 'w-screen md:w-[85%] py-3 h-[auto] bg-white   md:bg-[#f1f5f9]  md:p-10'
              }
            >
              <Routes>
                <Route path="subscription" element={<SubscribeSeller />} />

                <Route path="products-wanted" element={<WantedList />} />
                <Route path="products-wanted/:id" element={<ProposalForm />} />
                <Route path="propositions" element={<Proposals />} />
                <Route path="offers" element={<Offers />} />
                <Route path="offers/:id" element={<OfferDetail />} />
                <Route path="chat" element={<Chat />} />

                {/* Routes for Admin */}

                {/* Category */}
                <Route path="list-categories" element={<ShowCategories />} />
                <Route path="create-category" element={<CreateCategory />} />
                <Route path="edit-category/:id" element={<UpdateCategory />} />
                <Route path="detail-category/:id" element={<DetailCatgory />} />

                {/* Subcategory */}
                <Route
                  path="create-subcategory"
                  element={<CreateSubCategory />}
                />
                <Route
                  path="join-subcategory-tocategory"
                  element={<JoinSubCategoryToCategory />}
                />
                <Route
                  path="list-subcategories"
                  element={<ShowSubCategories />}
                />
                <Route
                  path="edit-subcategory/:id"
                  element={<UpdateSubCategory />}
                />

                {/* User */}
                <Route path="list-users" element={<ShowUsers />} />
                <Route path="detail-user/:id" element={<DetailUser />} />

                {/* Devises */}
                <Route path="list-currencies" element={<ListDevises />} />
                <Route path="create-currency" element={<CreateCurrency />} />

                {/* Means transport */}
                <Route
                  path="list-meanstransports"
                  element={<ListMeansTransports />}
                />
                <Route
                  path="create-meanstransport"
                  element={<CreateMeansTransport />}
                />
                <Route
                  path="edit-meanstransports/:id"
                  element={<EditMeansTransport />}
                />

                {/* Means transport */}
                <Route path="list-delivers" element={<ListDeliverAccepted />} />
                <Route path="tracker-contacts" element={<ListTrackers />} />

                {/* Product brands */}
                <Route path="product-brands" element={<ListProductBrand />} />
                <Route
                  path="add-product-brand"
                  element={<CreateProductBrand />}
                />

                <Route path="product-types" element={<ListProductType />} />
                <Route
                  path="add-product-type"
                  element={<CreateProductType />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
