import { Button, Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

export default function ErrorModal({ openModal, setOpenModal, text }) {
  const navigate = useNavigate()
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500">{text}</h3>
            <div className="flex justify-center gap-4 mb-5">
              <Button
                color="gray"
                onClick={() => {
                  navigate(-1)
                }}
              >
                ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
