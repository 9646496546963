import React from 'react'
import { useNavigate } from 'react-router-dom'
import uty from '../assets/uty.png'

function Title() {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate('/')}
      className="flex items-center py-2 transition-transform transform cursor-pointer hover:scale-105"
      style={{ color: '#333' }}
    >
      <img src={uty} alt="" className=" w-[25px] md:w-[40px] lg:w-[40px]" />
    </div>
  )
}

export default Title
