import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUserShopQuery } from '../../services/shopApi'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import DeleteModal from '../../components/DeleteModal'
import AnnouncemmentCard from '../../components/announcement/MyAnnouncementCard'
// import ShopDashInfo from '../../components/shops/ShopDashInfo'
import ReactGA from 'react-ga4'
import { useGetSellerAnnouncementsQuery } from '../../services/announcementApi'
import PageLoader from '../../components/PageLoader'
import Nav from '../../components/layout/Nav'
import { DetailModal } from '../../components/announcement/DetailModal'
import { useSelector } from 'react-redux'
import ShopDashHeader from '../../components/shops/ShopDashHeader'

export default function PublishedAnnouncements() {
  // let location = useLocation()
  // let params = useParams()
  let currentUser = useSelector((state) => state.user.currentUser)
  let { data: shop, isLoading } = useGetUserShopQuery(currentUser?._id)
  let { data: announcements } = useGetSellerAnnouncementsQuery(currentUser?._id)
  // let announcements = shop?.announcements
  // let shop = location.state.key
  console.log('shop', shop)
  const navigate = useNavigate()

  const [deleting, setDeleting] = useState(false)
  const [selected, setSelected] = useState()

  const [open, setOpen] = useState(false)

  console.log('announcements', announcements)

  if (isLoading)
    return (
      <div>
        <Nav />
        <PageLoader message="Chargement de la boutique..." />
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div className="">
        <Nav />
        <ShopDashHeader shop={shop} />
        <div className="pt-52">
          <div className="flex flex-col gap-5 justify-center items-center pb-5">
            <FaMoneyBillTrendUp className="text-[50px] text-slate-500" />
            <div className="text-center text-[10px] lg:text-[15px]">
              {"Vous n'avez pas d'annonces pour le moment"}
            </div>
            <div>
              <button
                className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
                onClick={() => {
                  ReactGA.event({
                    category: 'Announcement',
                    action: 'Want to post',
                  })
                  navigate('/announcements/create')
                }}
              >
                <span className="pr-1">
                  <IoMegaphoneOutline />
                </span>
                <span>Publiez une annonce</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  //if user is seller get all annoucements published else get annoucement published when it was user

  return (
    <div className=" ">
      <Nav />
      <ShopDashHeader shop={shop} />
      <div className="px-[16px] lg:px-20 w-[100%] pt-52 md:pt-[150px]">
        <div className="flex justify-between items-center pb-5 md:justify-between md:pt-10 md:items-center w-[100%]">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Mes Produits</h2>
            <p className="mt-2 text-sm text-gray-500">
              Gerer et modifier vos produits et services publiés sur la
              plateforme
            </p>
          </div>
        </div>
        {announcements?.length > 0 && (
          <div className="grid gap-5 pb-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {announcements?.map((announcement) => {
                return (
                  <AnnouncemmentCard
                    key={announcement?._id}
                    announcement={announcement}
                    setSelected={setSelected}
                    setDeleting={setDeleting}
                    setOpen={setOpen}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      {deleting && (
        <DeleteModal
          setOpenModal={setDeleting}
          selected={selected}
          openModal={deleting}
        />
      )}
      {open && (
        <DetailModal
          setOpenModal={setOpen}
          openModal={open}
          announcementId={selected}
        />
      )}
    </div>
  )
}
