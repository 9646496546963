import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import '../../pages/Carosel.css'
import '../../styles/carosel.css'
import { useNavigate } from 'react-router-dom'
// import { useGetAllAnnouncementsQuery } from '../../services/announcementApi'
import {
  FaRegHeart,
  // FaSpinner
} from 'react-icons/fa'
import { NumericFormat } from 'react-number-format'
import { AiOutlineEye } from 'react-icons/ai'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useSelector } from 'react-redux'
import { postSeen } from '../../services/seens/add-seen'

const AnnouncementsMoreView = ({ announcements, isLoading }) => {
  AOS.init()

  const currentUser = useSelector((state) => state.user.currentUser)

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const navigate = useNavigate()

  const filteredAnnounces = announcements
    ?.filter((announcement) => announcement?.seen?.length > 0)
    ?.sort((a, b) => b?.seen?.length - a?.seen?.length)
    ?.slice(0, 10)

  const hanleAddSeen = async (announce) => {
    const data = {
      announcementId: announce._id,
      userId: currentUser?._id,
    }

    if (currentUser) {
      await postSeen(data)
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`, {
        state: { key: announce },
      })
    } else {
      navigate(`/announcements/${announce._id}/${announce?.category?._id}`, {
        state: { key: announce },
      })
    }
  }

  return (
    <div className="pb-16 lg:px-16 md:px-10">
      <div className="px-[16px] lg:px-0 carousel-container">
        {!isLoading ? (
          <Loader />
        ) : (
          <div>
            <h2 className="text-[#21344e] text-[16px] font-bold pb-2 lg:pb-0 lg:pt-0 capitalize md:text-[18px]">
              Les annonces les plus vues
            </h2>
            {
              <Slider {...settings}>
                {filteredAnnounces?.map((announcement, index) => (
                  <div
                    onClick={() => hanleAddSeen(announcement)}
                    key={announcement?._id}
                    className="p-1 lg:p-4 carousel-item"
                    // data-aos="fade-up"
                    // data-aos-duration="400"
                  >
                    <div className="relative overflow-hidden transition-shadow duration-300 bg-white rounded-lg shadow-lg hover:shadow-xl">
                      <img
                        src={announcement?.images[0]}
                        className="w-full h-[150px] object-cover cursor-pointer"
                        alt={`Slide ${index + 1}`}
                      />

                      {/* Icônes superposées sur l'image */}
                      <div className="absolute flex space-x-2 top-2 right-2">
                        <div className="p-2 bg-white rounded-full shadow-md">
                          <FaRegHeart className="text-red-500" />
                        </div>
                        <div className="p-2 bg-white rounded-full shadow-md">
                          <AiOutlineEye className="text-blue-500" />
                        </div>
                      </div>

                      <div className="p-4 cursor-pointer">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800 truncate">
                          {announcement?.name}
                        </h3>
                        <div className="flex items-center justify-between mb-4">
                          <div className="font-bold text-gray-800 text-md">
                            <NumericFormat
                              value={announcement?.price}
                              displayType={'text'}
                              thousandSeparator=" "
                              suffix={` ${announcement?.currency?.shortName || 'fc'}`}
                            />
                          </div>
                        </div>
                        <button
                          className="w-full px-4 py-2 text-white transition-colors duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                          onClick={() => () => hanleAddSeen(announcement)}
                        >
                          Détail
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            }
          </div>
        )}
      </div>
    </div>
  )
}

export default AnnouncementsMoreView

function Loader() {
  const [loaderCount, setLoaderCount] = useState([])

  useEffect(() => {
    const updateLoaderCount = () => {
      const width = window.innerWidth

      if (width >= 1024) {
        setLoaderCount([1, 2, 3, 4, 5])
      } else if (width >= 768) {
        setLoaderCount([1, 2, 3])
      } else {
        setLoaderCount([1, 2])
      }
    }

    updateLoaderCount()

    window.addEventListener('resize', updateLoaderCount)

    return () => {
      window.removeEventListener('resize', updateLoaderCount)
    }
  }, [])

  return (
    <div className="w-full ">
      <div className="grid grid-cols-5 gap-2 md:grid-cols-3 lg:grid-cols-5">
        {loaderCount?.map((index) => (
          <div
            key={index}
            className=" rounded-[5px] py-2 my-2 cursor-pointer w-full mb-1 border-[0.001rem] pb-2 shadow-[0_0_0_rgba(48, 48, 49, 0.3)] hover:shadow-[0_0_15px_rgba(20,_20,_20,_0.2)] transition-all ease-in-out delay-150 md:h-[auto] "
          >
            <div className="flex flex-col items-center gap-2 px-2">
              <div
                alt=""
                className="flex-shrink-0 mt-[6px] w-full lg:mt-0 h-[60px] rounded-[5px]  md:h-[85px] hover:cursor-pointer  image animated-background"
              />
              <div
                alt=""
                className="h-[20px] w-[70px] md:w-[100%] animated-background hover:cursor-pointer"
              />
              <div
                alt=""
                className="h-[20px] w-[70px] md:w-[100%] animated-background hover:cursor-pointer"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Loader, AnnouncementsMoreView }
