import React from 'react'
import SearchBar from '../../SearchBar'
import Title from '../../Title'
// import IconList from './IconList'
import LinksOptions from '../options/LinksOptions'
// import NotificationIcon from './NotificationIcon'
// import { useGetAllAnnouncementsQuery } from '../../../services/announcementApi'
// import SearchIcon from './SearchIcon'
// import { Link } from '@mui/material'
// import { FiShoppingCart, FiUser } from 'react-icons/fi'
// import { IoNotificationsOutline } from 'react-icons/io5'
// import { FaRegMessage } from 'react-icons/fa6'

function TopBar() {
  return (
    <div className=" flex px-[16px] md:px-10 lg:px-16 justify-between items-center shadow-md bg-white z-50 left-0 right-0  h-10 md:h-16 lg:h-16">
      <Title />
      {/* <SearchBar /> */}
      <div className=" md:hidden sm:hidden lg:flex lg:gap-8">
        <SearchBar />
      </div>

      {/* <div className=" md:block sm:hidden lg:flex lg:gap-8">
        <IconList />
      </div> */}
      <div className="flex items-center gap-5">
        {/* <SearchIcon /> */}
        <LinksOptions />

        {/* <nav className="flex items-center space-x-6">
          <Link to="/account" className="hover:text-secondary-300">
            <FiUser className="w-6 h-6" />
          </Link>
          <Link to="/cart" className="hover:text-secondary-300">
            <FiShoppingCart className="w-6 h-6" />
          </Link>
          <Link to="/cart" className="hover:text-secondary-300">
            <IoNotificationsOutline className="w-6 h-6" />
          </Link>
          <Link to="/cart" className="hover:text-secondary-300">
            <FaRegMessage className="w-6 h-6" />
          </Link>
        </nav> */}
      </div>
    </div>
  )
}

export default TopBar
