import React from 'react'
import ProductCard from '../ProductCard'

function OtherAnnouncementsSeller({
  announcementsByCategory,
  loader,
  announcement,
}) {
  return (
    <div className="pt-1 pb-10">
      <div className="flex items-center gap-2 pb-4 md:pt-10">
        <h2 className="text-[#21344e] text-[16px] font-bold    capitalize md:text-[21px]">
          Autres produits
        </h2>
        <div>/</div>
        <h2 className="italic font-bold opacity-70">
          {announcement?.category.name}
        </h2>
      </div>

      <div
        className="flex transition-transform duration-300 ease-in-out"
        // style={{ transform: `translateX(-${currentIndex * (100 / itemsPerPage)}%)` }}
      >
        <ProductCard products={announcementsByCategory} loader={loader} />
      </div>
    </div>
  )
}

export default OtherAnnouncementsSeller
