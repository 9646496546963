import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'
import Nav from '../../components/layout/Nav'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import Profil from '../../assets/profil.png'

import OtherAnnouncementsSeller from '../../components/announcement/OtherAnnouncementsSeller'
import { getCommentByAnnouncement } from '../../services/Comments/getCommentByAnnouncement'
import CommentsAnnouncement from '../../components/Comments/CommentsAnnouncement'

// import { getAnnouncementsByCategory } from '../../services/Announcements/get-announcementsByCategory'
import ImageAnnounceCarrousel from '../../components/announcement/image-announce-carrousel'
import Register from '../authentification/Register'
import RegisterModal from '../../components/modal/RegisterModal'
// import Socials from '../../components/announcement/Socials'
import InfoUser from '../../components/announcement/Info-user'
import { postTrackerContact } from '../../services/trackers/post-tracker-contact'
// import { NumericFormat } from 'react-number-format'
import { fistLetterToUpparcase } from '../../helpers/firstLetterToUpperCase'
// import { AiOutlineMessage } from 'react-icons/ai'
import AddToCart from '../../components/cart/AddToCart'
import ProfilSeller from '../../components/announcement/ProfilSeller'
import CountLikeAndView from '../../components/announcement/CountLikeAndView'
import InfoProduct from '../../components/announcement/InfoProduct'
import { postSeen } from '../../services/seens/add-seen'
import {
  useAddLikeAnnouncementMutation,
  useGetAnnouncementQuery,
  useGetAnnouncementsByCategoryQuery,
} from '../../services/announcementApi'
import { LoaderHome } from '../Home'

function AnnouncementDetail() {
  const params = useParams()

  const scrolRef = useRef(null)

  const scrolRefContent = useRef(null)

  const {
    data: announcementsByCategory,
    isSuccess: isSuccessAnCategory,
    isLoading: isLoadingCategory,
  } = useGetAnnouncementsByCategoryQuery(params?.category, {
    skip: !params?.category,
    refetchOnMountOrArgChange: true,
  })

  const { data: announcement, isLoading: isLoadingAnnouncement } =
    useGetAnnouncementQuery(params?.id, {
      skip: !params?.id,
      refetchOnMountOrArgChange: true,
    })

  const [addLikeAnnouncement] = useAddLikeAnnouncementMutation()

  const [loaderBtn, setLoaderBtn] = useState(false)
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')
  const [isShowComment, setIsShowComment] = useState(false)

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  const [loadingProductId, setLoadingProductId] = useState(null) // État local pour suivre le produit cliqué

  let generateCodeForNoUser = 'yeueuizooslzukjsk'

  const navigate = useNavigate()
  const infoUserRef = useRef(null)
  const [fixedCall, setFixedCall] = useState(false)

  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {}, [params.id])

  useEffect(() => {
    if (announcement?._id) {
      getCommentByAnnouncement(announcement?._id, setComments)
    }

    if (currentUser) {
      postSeen({ announcementId: announcement?._id, userId: currentUser?._id })
    }
  }, [announcement?._id])

  let owner = announcement?.seller
    ? announcement?.seller?.user?._id
    : announcement?.user?._id

  const conversationData = {
    senderId: currentUser?._id,
    receiverId: owner,
  }

  let dataTracker = {
    typetracker: 'messagerieuty',
    announcement: announcement?._id,
  }

  const handleConversation = async (e) => {
    postTrackerContact(currentUser?._id, announcement?.user?._id, dataTracker)
    e.preventDefault()
    ReactGA.event({
      category: 'Announcement',
      action: 'Want to buy a product',
    })
    if (currentUser) {
      const chat = await axios.post(
        `${BASE_URL}/api/chat/create-chat`,
        conversationData
      )
      try {
        if (chat.data.message === 'success') {
          localStorage.setItem('chat', JSON.stringify(chat.data.chat))
          sendMessage(chat?.data?.chat?._id)
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      setConnecting(true)
    }
  }

  const sendMessage = async (chatId) => {
    setLoaderBtn(true)
    //send message in socket
    const message = {
      senderId: currentUser?._id,
      chatId: chatId,
      announcementId: params?.id,
      receiverId: owner,
    }

    try {
      const { data } = await axios.post(`${BASE_URL}/api/message/send`, message)

      if (data.result) {
        navigate(
          `/conversations/${currentUser?._id}?user=${currentUser?.username}`
        )
        setLoaderBtn(false)
      }
    } catch (err) {
      setLoaderBtn(false)
      console.log(err)
    }
  }

  const handleComment = async () => {
    const commentData = {
      user: currentUser?._id,
      announcement: params?.id,
      comment: comment,
    }

    if (comment) {
      try {
        const { data } = await axios.post(
          `${BASE_URL}/api/comment/add-comment`,
          commentData
        )
        if (data) {
          window.location.reload()
        }
      } catch (err) {
        console.log(err)
      }

      setComment('')
    }
  }

  const handleLikeAnnouncement = async (announcement) => {
    if (currentUser) {
      setLoadingProductId(announcement._id)

      try {
        await addLikeAnnouncement({
          userId: currentUser?._id,
          announcementId: announcement?._id,
        })
      } finally {
        setLoadingProductId(null)
      }
    }
  }

  useEffect(() => {
    if (scrolRefContent.current) {
      scrolRefContent.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [params])

  // Move Social and call
  useEffect(() => {
    const handleScroll = () => {
      const infoUserElement = infoUserRef.current
      if (infoUserElement) {
        const rect = infoUserElement.getBoundingClientRect()
        if (rect.top < 35 && !fixedCall) {
          setFixedCall(true)
        } else if (window.scrollY === 0 && fixedCall) {
          setFixedCall(false)
        }
      }
    }
    const debouncedHandleScroll = debounce(handleScroll, 100) // Debounce à 100ms
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
    }
  }, [fixedCall])

  //Clean effect scroll
  const debounce = (func, wait) => {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  return (
    <div className="relative" ref={scrolRefContent}>
      <div
        className={`flex px-4 ${isShowComment && 'hidden lg:flex'} flex-col md:px-16`}
      >
        <div>
          <Nav />
        </div>

        {!isLoadingAnnouncement && !isLoadingCategory ? (
          <>
            <div>
              <div className="flex flex-col pt-32 lg:pt-36 lg:flex-row md:pt-24 lg:gap-8 md:gap-6">
                <div>
                  <ImageAnnounceCarrousel
                    setIsShowComment={setIsShowComment}
                    images={announcement?.images}
                    handleLikeAnnouncement={handleLikeAnnouncement}
                    announcement={announcement}
                    user={currentUser}
                    loadingProductId={loadingProductId}
                  />
                  <InfoUser
                    announcement={announcement}
                    currentUser={currentUser}
                    comments={comments}
                    navigate={navigate}
                    params={params}
                    handleConversation={handleConversation}
                    loaderBtn={loaderBtn}
                    ref={null}
                    infoUserRef={infoUserRef}
                    fixedCall={fixedCall}
                    handleLikeAnnouncement={handleLikeAnnouncement}
                    generateCodeForNoUser={generateCodeForNoUser}
                  />
                </div>

                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:w-full lg:flex">
                  <div className="lg:w-full">
                    <div className="flex items-center justify-between pt-5 md:pt-0 lg:pt-0">
                      <ProfilSeller
                        announcement={announcement}
                        Profil={Profil}
                        fistLetterToUpparcase={fistLetterToUpparcase}
                      />
                      <CountLikeAndView announcement={announcement} />
                    </div>

                    <InfoProduct announcement={announcement} />

                    <hr className="my-3 border-blue-100 lg:my-6" />

                    <AddToCart
                      announcement={announcement}
                      user={currentUser}
                      loadingProductId={loadingProductId}
                      setConnecting={setConnecting}
                      generateCodeForNoUser={generateCodeForNoUser}
                      handleLikeAnnouncement={handleLikeAnnouncement}
                    />
                  </div>

                  <div className="hidden lg:w-full md:hidden lg:block ">
                    <CommentsAnnouncement
                      comments={comments}
                      navigate={navigate}
                      scrolRef={scrolRef}
                      currentUser={currentUser}
                      handleComment={handleComment}
                      announcement={announcement}
                      setComment={setComment}
                      isShowComment={isShowComment}
                      setConnecting={setConnecting}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <OtherAnnouncementsSeller
                announcement={announcement}
                announcementsByCategory={announcementsByCategory?.announcements}
                loader={isSuccessAnCategory}
              />
            </div>

            {connecting && (
              <Register
                openModal={connecting}
                setOpenModal={setConnecting}
                setRegistering={setRegistering}
              />
            )}

            {registering && (
              <RegisterModal
                openModal={registering}
                setOpenModal={setRegistering}
              />
            )}
          </>
        ) : (
          <LoaderHome />

          // <div className="flex flex-col w-full gap-5 pt-40 mb-8 lg:flex-row">
          //   <div className="h-96 lg:h-96 w-full lg:w-[40%] image animated-background mb-5  lg:mb-0 rounded-[3px]"></div>

          //   <div className="h-96 w-[100%] lg:w-[30%] ">
          //     <div className=" h-10 w-full image animated-background mb-5 lg:mb-4 rounded-[3px]"></div>
          //     <div className="h-10 w-full image animated-background mb-5 lg:mb-4  rounded-[3px]"></div>
          //     <div className="h-10 w-full image animated-background mb-5 lg:mb-4  rounded-[3px]"></div>
          //     <hr className="mb-4" />
          //     <div className=" h-16 w-full image animated-background mb-5 lg:mb-4  rounded-[3px]"></div>
          //     <div className="h-16 w-full image  animated-background mb-5 lg:mb-4  rounded-[3px]"></div>
          //     <div className="h-10 w-full image animated-background mb-5 lg:mb-4  rounded-[3px]"></div>
          //   </div>

          //   <div className="h-96 w-[30%] hidden image animated-background rounded-[3px] lg:flex flex-col justify-between p-2">
          //     <div className="flex gap-2">
          //       <div className="w-10 h-10 border rounded-full image animated-background"></div>
          //       <div className="h-10 w-full border image animated-background mb-4 rounded-[3px]"></div>
          //     </div>
          //     <div className="h-10 w-full border image animated-background mb-4 rounded-[3px]"></div>
          //   </div>
          // </div>
        )}
      </div>

      <div className={isShowComment ? 'absolute block  lg:hidden' : 'hidden'}>
        <CommentsAnnouncement
          comments={comments}
          navigate={navigate}
          scrolRef={scrolRef}
          currentUser={currentUser}
          handleComment={handleComment}
          announcement={announcement}
          setComment={setComment}
          setIsShowComment={setIsShowComment}
          setConnecting={setConnecting}
        />
      </div>
    </div>
  )
}

export default AnnouncementDetail
