import React from 'react'
import { useAddToCartMutation } from '../../services/cartApi'
import {
  FaCartArrowDown,
  FaHeart,
  FaRegHeart,
  FaPlus,
  FaMinus,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setCart } from '../../features/cart/cart'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export default function AddToCart({
  announcement,
  user,
  setConnecting,
  generateCodeForNoUser,
  handleLikeAnnouncement,
  loadingProductId,
}) {
  const dispatch = useDispatch()
  const [quantity, setQuantity] = React.useState(1)
  const [addToCart, { isLoading }] = useAddToCartMutation()

  const currentUser = useSelector((state) => state?.user?.currentUser)

  const handleAddToCart = (e, userId, productId, quantity) => {
    e.preventDefault()
    let productData = {
      userId: userId,
      productId: productId,
      quantity: quantity,
    }
    user
      ? addToCart(productData).then((response) =>
          dispatch(setCart(response?.data))
        )
      : setConnecting(true)
  }

  const increment = (e, n) => {
    e.preventDefault()
    setQuantity(n + 1)
  }

  const decrement = (e, n) => {
    e.preventDefault()
    if (n > 1) {
      setQuantity(n - 1)
    }
  }

  return (
    <div className="flex gap-2 pb-10 mt-4 lg:flex-row lg:gap-4 lg:pb-0">
      <div className="flex items-center gap-2 ">
        <label htmlFor="quantity" className="font-semibold text-gray-700">
          Qté
        </label>
        <div className="flex items-center overflow-hidden border border-gray-200 rounded-lg">
          <button
            className="p-1 text-white transition-colors duration-200 ease-in-out bg-blue-500 hover:bg-blue-600"
            onClick={(e) => increment(e, quantity)}
          >
            <FaPlus />
          </button>
          <span className="px-4 py-2 text-lg font-semibold text-gray-700 bg-white">
            {quantity}
          </span>
          <button
            className="p-1 text-white transition-colors duration-200 ease-in-out bg-blue-500 hover:bg-blue-600"
            onClick={(e) => decrement(e, quantity)}
          >
            <FaMinus />
          </button>
        </div>
      </div>
      <div className="flex items-center flex-1 gap-2 lg:gap-4 ">
        <button
          onClick={(e) =>
            handleAddToCart(e, user?._id, announcement?._id, quantity)
          }
          className="flex items-center justify-center flex-1 gap-2 py-2 text-sm text-white transition duration-300 ease-in-out bg-blue-600 rounded-lg lg:px-2 hover:bg-blue-700"
        >
          {isLoading ? (
            'En cours...'
          ) : (
            <>
              <FaCartArrowDown /> Ajouter au panier
            </>
          )}
        </button>
        <button
          className="p-2 transition duration-300 ease-in-out bg-gray-200 rounded-full hover:bg-gray-300"
          onClick={() => handleLikeAnnouncement(announcement)}
        >
          {loadingProductId === announcement?._id ? (
            <AiOutlineLoading3Quarters className="w-4 h-4 text-blue-500 animate-spin" />
          ) : announcement?.likes?.includes(currentUser?._id) ||
            announcement?.likes?.includes(generateCodeForNoUser) ? (
            <FaHeart className="text-xl text-red-400" />
          ) : (
            <FaRegHeart className="text-xl text-gray-600" />
          )}
        </button>
      </div>
    </div>
  )
}
