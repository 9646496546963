import { useState } from 'react'
import Register from '../pages/authentification/Register'
import RegisterModal from './modal/RegisterModal'
import BannerHeader from '../assets/banner/banner.jpeg'
import { useNavigate } from 'react-router-dom'
// import { FaMoneyBill } from 'react-icons/fa'
import { MdOutlinePublish } from 'react-icons/md'
import { useSelector } from 'react-redux'
import ScrollListCategories from './chats/ScrollListCategories'
import ScrollListCategoriesMobile from './chats/ScrollListCategoriesMobile'
import BannerSlider from './home/BannerSlider'

function HomeHeader() {
  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)
  let navigate = useNavigate()
  let currentUser = useSelector((state) => state.user.currentUser)
  let shop = useSelector((state) => state.user.myShop)

  const handleCommand = (e) => {
    e.preventDefault()
    currentUser ? navigate(`/categories`) : setConnecting(true)
  }

  const handleSell = (e) => {
    e.preventDefault()
    currentUser
      ? shop
        ? navigate(`/shop/me/${shop._id}`)
        : navigate(`/announcements/me/${currentUser._id}`)
      : setConnecting(true)
  }

  return (
    <div className="relative">
      <header className="relative z-30 sm:hidden md:hidden lg:block">
        <div
          className="header-container"
          style={{ backgroundImage: `url(${BannerHeader})` }}
        >
          <div className="header-overlay"></div>
          <div className="flex flex-col items-center justify-center px-16 pt-28 header-content">
            <div className="flex items-center justify-between w-full">
              <div className=" lg:col-span-3 md:col-span-5">
                {/* Ajoutez votre contenu ici */}
                {/* Exemple de contenu */}
                <h1 className="mb-4 text-4xl font-bold">Bienvenue sur uty</h1>
                <p className="text-lg ">
                  Commandez et recevez vos achats directement à votre porte.
                </p>
                <div className="flex gap-5 mt-4">
                  <button
                    className="px-4 py-2 text-white transition border border-white rounded hover:bg-white hover:text-black"
                    onClick={(e) => handleCommand(e)}
                  >
                    Commandez
                  </button>
                  <button
                    className="px-4 py-2 text-white transition border border-white rounded hover:bg-white hover:text-black"
                    onClick={(e) => handleSell(e)}
                  >
                    Vendez
                  </button>
                </div>
              </div>
              <button
                className="flex items-center gap-3 px-4 py-2 font-semibold text-[rgba(0,0,0,0.78)] transition bg-[#f7f7f7] border border-white rounded hover:opacity-50 hover:bg-white hover:text-black"
                onClick={() => navigate('/categories')}
              >
                Tous les produits <MdOutlinePublish />
              </button>
            </div>
          </div>
        </div>
        {connecting && (
          <Register
            openModal={connecting}
            setOpenModal={setConnecting}
            setRegistering={setRegistering}
          />
        )}
        {registering && (
          <RegisterModal
            openModal={registering}
            setOpenModal={setRegistering}
          />
        )}
      </header>

      <div className="relative h-32 hidden md:block">
        <ScrollListCategories />
      </div>

      <div className="md:hidden block">
        <div className="md:hidden block pt-24">
          <BannerSlider />
        </div>
        <ScrollListCategoriesMobile />
      </div>
    </div>
  )
}

export default HomeHeader
