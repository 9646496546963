import React from 'react'
import { useParams } from 'react-router-dom'

import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import ItemProduct from '../../components/ItemProduct'

import PageLoader from '../../components/PageLoader'
import Nav from '../../components/layout/Nav'
// import ShopInfo from '../../components/shops/ShopInfo'
import ShopHeader from '../../components/shops/ShopHeader'

import { useGetShopQuery } from '../../services/shopApi'

export default function ShopProducts() {
  let params = useParams()
  let { data: shop, isLoading } = useGetShopQuery(params.id)
  let announcements = shop?.announcements

  if (isLoading)
    return (
      <div>
        <Nav />
        <PageLoader message="Chargement de la boutique..." />
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div className="">
        <Nav />
        <ShopHeader shop={shop} />
        <div className="pt-40">
          <div className="flex flex-col gap-5 justify-center items-center mt-5 mb-20">
            <FaMoneyBillTrendUp className="text-[50px] text-slate-500" />
            <div className="text-center text-[10px] lg:text-[15px]">
              {"Cette boutique n'a pas d'annonces pour le moment"}
            </div>
          </div>
        </div>
      </div>
    )
  }
  //if user is seller get all annoucements published else get annoucement published when it was user

  return (
    <div className=" ">
      <Nav />
      <ShopHeader shop={shop} />
      <div className="px-[16px] lg:px-20 w-[100%] pt-[150px]">
        <div className="mb-5">
          <h2 className="text-2xl font-bold text-gray-900">Nos Produits</h2>
          <p className="mt-2 text-sm text-gray-500">
            Découvrez notre sélection de produits soigneusement choisis pour
            vous
          </p>
        </div>
        {announcements?.length > 0 && (
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-5">
            <ItemProduct data={announcements} />
          </div>
        )}
      </div>
    </div>
  )
}
