import React from 'react'
// import { useNavigate } from 'react-router-dom'
import { Modal } from 'flowbite-react'
// import { useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import _ from 'lodash'

export default function PayOrder({
  openModal,
  setOpenModal,
  toPay,
  isOrdering,
  handleOrderCart,
}) {
  // const navigate = useNavigate()
  // const dispatch= useDispatch()

  // const currentUser = useSelector((state) => state.user.currentUser)
  // const thisCart = useSelector((state) => state.cart.cart)

  // const [phone, setPhone] = React.useState('')
  // const [phoneError, setPhoneError] = React.useState('')

  const totalPrices = React.useMemo(
    () => _.sum(_.flatten(_.values(toPay).map((p) => p.prices))),
    [toPay]
  )
  const totalShipmentFees = React.useMemo(
    () => _.sum(_.values(toPay).map((p) => p.shipmentFees)),
    [toPay]
  )
  const totalAmount = React.useMemo(
    () => _.round(totalPrices + totalShipmentFees, -2),
    [totalPrices, totalShipmentFees]
  )

  // const validPrefixes = React.useMemo(
  //   () => [
  //     '81',
  //     '82',
  //     '83',
  //     '84',
  //     '85',
  //     '89',
  //     '90',
  //     '92',
  //     '94',
  //     '95',
  //     '97',
  //     '98',
  //     '99',
  //   ],
  //   []
  // )

  // const validatePhone = React.useCallback(
  //   (phoneNumber) => {
  //     const isValid =
  //       !phoneNumber.startsWith('0') &&
  //       validPrefixes.some((prefix) => phoneNumber.startsWith(prefix)) &&
  //       phoneNumber.length === 9

  //     setPhoneError(isValid ? '' : 'Numéro de téléphone invalide.')
  //     return isValid
  //   },
  //   [validPrefixes]
  // )

  // const handlePhoneChange = (e) => {
  //   const inputPhone = e.target.value.replace(/\D/g, '') // Retire les caractères non numériques
  //   setPhone(inputPhone)
  //   if (inputPhone.length <= 9) {
  //     validatePhone(inputPhone)
  //   }
  // }

  // const pay = async () => {
  //   if (validatePhone(phone)) {
  //     const order = {
  //       user: currentUser?._id,
  //       data: toPay,
  //       cart: thisCart?._id,
  //       dropOffLocation,
  //       amount: totalAmount,
  //       phone,
  //     }

  //     try {
  //       await payOrders(order)
  //     } catch (error) {
  //       console.error('Erreur lors du paiement :', error)
  //     }
  //   }
  // }

  // React.useEffect(() => {
  //   if (typeof paymentData === 'string') {
  //     navigate('/invoice', {
  //       state: { key: paymentData },
  //     })
  //   }
  // }, [paymentData, navigate])

  return (
    <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
      <Modal.Header className="px-5 pt-10">
        <h2 className="text-[20px] font-bold text-gray-500">
          Validation du panier
        </h2>
      </Modal.Header>
      <Modal.Body className="px-6 py-4 pb-10">
        <div className="space-y-4">
          {/* Montant total */}
          <div className="text-center">
            <p className="text-gray-700">Montant total :</p>
            <p className="text-[50px] font-bold text-slate-300">
              <NumericFormat
                value={totalAmount}
                displayType="text"
                thousandSeparator=" "
                suffix=" FC"
              />
            </p>
          </div>

          <div>
            <p className="text-center text-gray-700">
              Paiement par mobile money momentanement indisponible
            </p>
          </div>

          {/* Numéro de téléphone */}
          {/* <div>
            <Label
              htmlFor="phone"
              value="Numéro Mobile Money"
              className="mb-2 block text-gray-300"
            />
            <TextInput
              id="phone"
              placeholder="821234567"
              addon="+243"
              value={phone}
              onChange={handlePhoneChange}
              color={phoneError ? 'failure' : 'base'}
              helperText={
                phoneError && <span className="text-red-600">{phoneError}</span>
              }
              required
              disabled={true}
            />
          </div> */}

          {/* Boutons d'action */}
          <div className="flex flex-col items-center space-y-2">
            {/* <button
              // onClick={pay}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition duration-200 disabled:bg-blue-300"
              disabled={true}
            >
              Payer maintenant
              {/* {isPaying ? 'Paiement en cours...' : 'Payer maintenant'} </button> */}

            {/* <div className="text-gray-500">ou</div> */}

            <button
              onClick={handleOrderCart}
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition duration-200 disabled:bg-gray-200"
            >
              {isOrdering ? 'Traitement...' : 'Payer à la livraison'}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
