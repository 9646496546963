import React from 'react'
import { IoCalendar, IoStar, IoTicket, IoHeart } from 'react-icons/io5'
// import UserAddress from '../location/UserAddress'
import { useNavigate } from 'react-router-dom'
// import Register from '../../pages/authentification/Register'
// import RegisterModal from '../modal/RegisterModal'
import _ from 'lodash'
import ReactGA from 'react-ga4'
import moment from 'moment'

export default function ShopHeader({ shop }) {
  const rating = shop?.ratings?.length > 0 ? _.mean(shop?.ratings) : 0
  const navigate = useNavigate()
  console.log('shop ratings', shop)
  return (
    <div
      className="relative"
      style={{
        background: 'linear-gradient(to right, #E0F7FA, #F0F0F0)',
        borderRadius: '8px',
      }}
    >
      {/* Hero section avec overlay */}
      <div className="relative h-80">
        <div className="" />
        {/* <div className="absolute inset-0 bg-black/20 backdrop-blur-[2px]" /> */}
      </div>

      {/* Contenu du header */}
      <div className="absolute top-0 left-0 right-0 h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-end">
          <div className="w-full transform translate-y-1/2">
            <div className="bg-white rounded-xl shadow-xl p-6">
              <div className="flex flex-col justify-center lg:flex-row lg:items-center lg:justify-between gap-8">
                {/* Info du shop */}
                <div>
                  <div className="sm:flex sm:items-center gap-6">
                    <div className="flex-shrink-0">
                      <img
                        className="mx-auto h-24 w-24 rounded-xl border-4 border-white shadow-lg"
                        src={shop?.logo}
                        alt={shop?.name}
                      />
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:text-left">
                      <h1 className="text-3xl font-bold text-gray-900">
                        {shop?.name}
                      </h1>
                      <p className="mt-2 text-lg text-gray-600 max-w-xl">
                        {shop?.description}
                      </p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mt-4">
                    <button
                      onClick={() =>
                        navigate(`/shop/update/${shop?._id}`, {
                          state: { key: shop },
                        })
                      }
                      className="bg-gray-300 text-gray-700 text-light hover:bg-blue-700 hover:text-white text-[10px] lg:text-[15px] py-2 px-4 rounded-lg"
                    >
                      Modifier la boutique
                    </button>
                    <button
                      className="bg-blue-500 md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
                      onClick={() => {
                        ReactGA.event({
                          category: 'Announcement',
                          action: 'Want to post from a shop',
                        })
                        navigate('/announcements/create')
                      }}
                    >
                      <span className="block lg:hidden text-[10px] lg:text-[15px]">
                        Ajouter un produit
                      </span>
                      <span className="lg:block sm:hidden md:hidden text-[10px] lg:text-[15px]">
                        Publier un produit
                      </span>
                    </button>
                  </div>
                </div>

                {/* Statistiques */}
                <div className="mt-6 sm:mt-0">
                  <div className="grid grid-cols-2 gap-4 lg:grid-cols-1">
                    {/* <div className="flex items-center gap-2">
                      <UserAddress location={shop?.location?.coordinates} />
                    </div> */}

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <IoHeart className="h-5 w-5 text-red-200" />
                      </div>
                      <span className="text-[10px] lg:text-[15px]">
                        {shop?.followers?.length || 0} followers
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-yellow-50 rounded-lg">
                        <IoStar className="h-5 w-5 text-yellow-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        {rating} / 5.0
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <IoTicket className="h-5 w-5 text-blue-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        {shop?.announcements?.length} produits
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-green-50 rounded-lg">
                        <IoCalendar className="h-5 w-5 text-green-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        Membre depuis{' '}
                        {moment(shop?.createdAt).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )} */}
    </div>
  )
}
