import React from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import {
  useGetCustomerOrdersQuery,
  useGetCustomerDeliveredOrdersQuery,
} from '../../services/orderApi'
import { useDispatch, useSelector } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import OrdersTracking from '../../components/orders/OrderTracking'
import CustomerOrdersHistory from '../../components/orders/CustomerOrdersHistory'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import PhoneNumberModal from '../../components/modal/PhoneNumberModal'
// import AddPhoneNumber from '../../components/user/AddPhoneNumber'

const tabs = (orders, deliveredOrders, user, isLoading) => [
  {
    name: 'Commandes en cours',
    component: (
      <OrdersTracking orders={orders} user={user} isLoading={isLoading} />
    ),
  },
  {
    name: 'Commandes livrées',
    component: <CustomerOrdersHistory orders={deliveredOrders} />,
  },
]

export default function CustomerOrders() {
  const params = useParams()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [addingPhone, setAddingPhone] = React.useState(false)

  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const { data: orders, isLoading } = useGetCustomerOrdersQuery(params.id)
  const { data: deliveredOrders } = useGetCustomerDeliveredOrdersQuery(
    params.id
  )

  const [activeTab, setActiveTab] = React.useState(0)

  React.useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))
  }, [])

  React.useEffect(() => {
    if (!currentUser?.phone && !currentUser?.verified_phone) {
      setAddingPhone(true)
    }
  }, [])

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Nav />
        <div className="flex flex-col pt-24 items-center justify-center">
          <LottieLoader animation={indexLoader} />
          <p className="mt-4 text-gray-500">
            Chargement de mes commandes en cours...
          </p>
        </div>
      </div>
    )

  return (
    <div className="bg-gray-100 min-h-screen">
      <Nav />
      <div className="mx-0 lg:mx-auto py-16 pt-36 px-5 lg:px-48">
        <h2 className="text-xl font-bold text-gray-800 mb-4 sm:mb-8">
          Mes commandes et achats
        </h2>
        <div className="flex justify-between mb-6">
          {tabs(orders, deliveredOrders, params.id, isLoading).map(
            (tab, index) => (
              <button
                key={index}
                className={`px-2 py-2 w-[40vw] sm:w-[40vw] lg:w-[30vw] rounded-full font-medium text-[10px] lg:text-[15px] text-gray-700 focus:outline-none ${
                  activeTab === index
                    ? 'bg-blue-500 text-white shadow-md'
                    : 'bg-white shadow hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.name}
              </button>
            )
          )}
        </div>
        <div className="">
          {
            tabs(orders, deliveredOrders, params.id, isLoading)[activeTab]
              .component
          }
        </div>
      </div>
      {addingPhone && (
        <PhoneNumberModal
          isOpen={addingPhone}
          onRequestClose={() => setAddingPhone(false)}
        />
      )}
    </div>
  )
}
