import React from 'react'
import Nav from '../../components/layout/Nav'
import { useGetFavoritesAnnouncementsQuery } from '../../services/announcementApi'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import LottieLoader from '../../components/LottieLoader'
import { useNavigate } from 'react-router-dom'
import { IoHeartDislikeCircleOutline } from 'react-icons/io5'
import ProductCard from '../../components/ProductCard'

const Favorites = () => {
  const navigate = useNavigate()
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const {
    data: announcements,
    isFetching,
    isSuccess,
  } = useGetFavoritesAnnouncementsQuery(currentUser._id)

  if (isFetching)
    return (
      <div>
        <Nav />
        <div className="flex flex-col items-center justify-center sm:h-screen">
          <div>
            <LottieLoader animation={indexLoader} />
          </div>
          <div>Chargement de mes annonces... </div>
        </div>
      </div>
    )

  if (announcements?.length === 0) {
    return (
      <div>
        <Nav />
        <div className="flex flex-col items-center justify-center gap-5 pt-36 md:pt-40">
          <IoHeartDislikeCircleOutline className="text-[50px] text-slate-500" />
          <div>{"Vous n'avez pas aimé d'annonces pour le moment"}</div>
          <div>
            <button
              className="bg-primary md:mt-0 py-[5px] px-2 md:py-2 md:px-4 text-light text-white text-sm rounded-lg flex items-center"
              onClick={() => {
                navigate('/categories')
              }}
            >
              <span>Voir les produits disponibles</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Nav />
      <div className="px-5 pt-24 pb-10 md:pt-32 lg:px-32">
        <div className="py-5">
          <h5 className="text-2xl font-bold text-gray-800">
            Liste de mes annonces favorites
          </h5>

          <p className="mt-1 mb-3 text-sm text-gray-600 line-clamp-2">
            Retrouvez les annonces que vous avez aimées et contactez le vendeur
            pour acheter vos produits favoris
          </p>
        </div>

        <div className="flex transition-transform duration-300 ease-in-out">
          <ProductCard products={announcements} loader={isSuccess} />
        </div>
      </div>
    </div>
  )
}

export default Favorites
