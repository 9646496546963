import React from 'react'
import { formatDistance } from '../../helpers/FormatDistance'
import { secondsToHms } from '../../helpers/FormatDuration'
import { Spinner } from 'flowbite-react'
import _ from 'lodash'

function ShipmentDatas({ trajectory, loading }) {
  console.log('trajectory', trajectory)
  const shipDistance = formatDistance(trajectory?.distance / 1000)
  let datas = [
    {
      title: 'Distance: ',
      value: shipDistance,
    },
    {
      title: 'Durée estimée en auto: ',
      value: secondsToHms(trajectory?.duration),
    },
    {
      title: 'Coût: ',
      value: _.ceil((parseInt(trajectory?.distance) * 1500) / 1000) + ' FC',
    },
  ]

  return (
    <div className="flex gap-3 py-6 px-4 bg-gray-50 rounded-lg shadow-md">
      {datas.map((data, index) => {
        return (
          <>
            {loading ? (
              <div className="flex flex-col justify-center items-center gap-1 bg-white p-4 w-[30%] rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 ease-in-out">
                <Spinner
                  color="info"
                  size="xl"
                  aria-label="Chargement du trajet..."
                />
              </div>
            ) : (
              <div
                key={index}
                className="flex flex-col justify-center items-start gap-1 bg-white p-4 w-[30%] rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 ease-in-out"
              >
                <span className="text-gray-600 text-[10px] lg:text-[15px]">
                  {data.title}
                </span>
                <span className="font-bold text-[10px] lg:text-[15px] text-gray-800">
                  {data.value}
                </span>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default ShipmentDatas
