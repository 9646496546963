import React from 'react'
import logo from '../../../assets/uty.png'

export default function LoginHeader() {
  return (
    <div className="flex justify-center items-center flex-col gap-3">
      <div>
        <img src={logo} alt="logo" className="w-20 h-20" />
      </div>
      <span className="text-[19px] font-medium text-gray-500 pb-3">
        Kota, somba, pe teka na uty
      </span>
    </div>
  )
}
