// src/components/modal/PhoneNumberModal.jsx
import React, { useState } from 'react'
import { Modal } from 'flowbite-react'
import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentUser } from '../../features/user/user'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'

const PhoneNumberModal = ({ isOpen, onRequestClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('243') // Default prefix
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)

  const validatePhoneNumber = (number) => {
    const regex = /^(243)(81|82|83|84|85|89|90|91|92|94|95|97|98|99)(\d{7})$/
    return regex.test(number)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (validatePhoneNumber(phoneNumber)) {
      console.log('Phone Number Submitted: ', phoneNumber)
      await axios
        .post(`${BASE_URL}/api/users/add-telephone/${currentUser?._id}`, {
          phone: phoneNumber,
        })
        .then((response) => {
          console.log(response.data)
          dispatch(setCurrentUser(response.data.user))
          localStorage.setItem(
            'currentUser',
            JSON.stringify(response.data.user)
          )
          setSuccess(true)
        })
      // onRequestClose(); // Close modal after submission
      setErrorMessage('') // Clear any previous error message
    } else {
      setErrorMessage(
        "Numéro de téléphone invalide. Assurez-vous qu'il commence par 243 et suit les critères spécifiés."
      )
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    // Allow only digits and limit input to 12 characters (including prefix)
    if (/^\d*$/.test(value) && value.length <= 12) {
      setPhoneNumber(value)
    }
  }

  return (
    <Modal show={isOpen} size="sm" onClose={onRequestClose}>
      <Modal.Body className="flex flex-col gap-4">
        <div>
          {success ? (
            <div className="text-center flex flex-col justify-center gap-4">
              <IoCheckmarkCircleOutline className="mx-auto mb-4 h-14 w-14 text-green-400 dark:text-gray-200" />
              <p>Numéro ajouté avec succès</p>
              <div className="">
                <button
                  onClick={onRequestClose}
                  className="btn bg-green-500 text-white hover:bg-blue-600 rounded-md px-4 py-2"
                >
                  Ok, d{"'"}accord
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h5 className="text-[13.5px]">
                  Restez joignable pendant le processus de livraison de votre
                  commande
                </h5>
                <p className="text-[13px]">Ajouter votre numéro de téléphone</p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                  className="input w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <div className="flex justify-end space-x-2">
                  <button
                    type="submit"
                    className="btn bg-green-500 text-white hover:bg-blue-600 rounded-md px-4 py-2"
                  >
                    {loading ? 'Ajout en cours...' : 'Ajouter'}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PhoneNumberModal
