import { Button, Label, TextInput, Textarea, Select } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { weightRanges } from '../../helpers/announcements/weight-ranges'
import { rentChoice } from '../../helpers/announcements/rent-choice'
import {
  _showInputQuantity,
  _showWeightRange,
  _showInputRent,
} from '../../helpers/announcements/hiden-and-show-elements'

export default function CreateFormNext({
  setNext,
  price,
  currency,
  description,
  quantity,
  category,
  setPrice,
  currencies,
  setQuantity,
  setCurrency,
  setDescription,
  isLoading,
  isSuccess,
  handleSubmit,
  weightRange,
  setWeightRange,
  rent,
  setRent,
  brands,
  setBrand,
  brand,
  type,
  setType,
  newtypes,
  newBrands,
}) {
  const [priceError, setPriceError] = useState('')
  const [currencyError, setCurrencyError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [quantityError, setQuantityError] = useState('')

  let showInputQuantity = _showInputQuantity(category)
  let showWeightRange = _showWeightRange(category)
  let showInputRent = _showInputRent(category)

  useEffect(() => {
    if (!weightRange) setWeightRange(weightRanges[0])
    if (!rent) setRent(rentChoice[0])
  }, [])

  const validateForm = () => {
    let isValid = true
    if (!setPrice) {
      setPriceError('Le prix est requis.')
      isValid = false
    } else {
      setPriceError('')
    }

    if (!setCurrency) {
      setCurrencyError('La devise est requise.')
      isValid = false
    } else {
      setCurrencyError('')
    }

    if (!setDescription) {
      setDescriptionError('La description est requise.')
      isValid = false
    } else {
      setDescriptionError('')
    }

    if (!setQuantity) {
      setQuantityError('La quantité est requise')
      isValid = false
    } else {
      setQuantityError('')
    }

    return isValid
  }

  const handlePublish = () => {
    if (validateForm()) {
      handleSubmit()
    }
  }

  return (
    <form className="flex flex-col max-w-md gap-4 ">
      {/*product brands */}
      <div>
        {newBrands?.length > 0 && (
          <div className="max-w-md mb-3">
            <div className="block mb-2">
              <Label htmlFor="brands" value="Choisir la marque de produit" />
            </div>
            <Select
              id="brands"
              onChange={(e) => {
                let value = e.target.value
                if (value) {
                  const selectedBrand = JSON.parse(value)
                  setBrand(selectedBrand)
                } else {
                  setBrand(null)
                }
              }}
              value={brand ? JSON.stringify(brand) : null}
              required
            >
              <option value="">Choisissez une marque</option>
              {brands?.map((brand) => (
                <option key={brand._id} value={JSON.stringify(brand) || null}>
                  {brand.name}
                </option>
              ))}
            </Select>
          </div>
        )}
        {/* <div className="max-w-md mb-3">
          <div className="block mb-2">
            <Label htmlFor="brands" value="Choisir la marque de produit" />
          </div>
          <Select
            id="brands"
            onChange={(e) => {
              let value = e.target.value
              if (value) {
                const selectedBrand = JSON.parse(value)
                setBrand(selectedBrand)
              } else {
                setBrand(null)
              }
            }}
            value={brand ? JSON.stringify(brand) : null}
            required
          >
            <option value="">Choisissez une marque</option>
            {brands?.map((brand) => (
              <option key={brand._id} value={JSON.stringify(brand) || null}>
                {brand.name}
              </option>
            ))}
          </Select>
        </div> */}

        {/* product Types */}
        {newtypes?.length > 0 && (
          <div className="max-w-md mb-3">
            <div className="block mb-2">
              <Label htmlFor="types" value="Choisir le type de produit" />
            </div>
            <Select
              id="types"
              onChange={(e) => {
                let value = e.target.value
                if (value) {
                  const selectedType = JSON.parse(value)
                  setType(selectedType)
                } else {
                  setType(null)
                }
              }}
              value={type ? JSON.stringify(type) : null}
              required
            >
              <option value="">Choisissez un type de produit</option>
              {newtypes?.map((type) => (
                <option key={type._id} value={JSON.stringify(type) || null}>
                  {type.name}
                </option>
              ))}
            </Select>
          </div>
        )}

        {showInputRent && (
          <div className="">
            <div className="block mb-2">
              <Label
                htmlFor="weightRange"
                value="De quel type d'offre s'agit-il ?"
              />
            </div>
            <div className="flex gap-10">
              {' '}
              {rentChoice?.map((rc, index) => (
                <div key={rc?.value} className="flex items-center mb-2">
                  <input
                    onChange={() => setRent(rc)}
                    checked={rent?.value === rc?.value && true}
                    value={rent?.value}
                    id="checkbox"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor={`checkbox-${index}`}
                    className="text-sm font-medium text-gray-900 ms-2 dark:text-gray-300"
                  >
                    {rc?.value}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex gap-2">
          <div className="flex-grow">
            <div className="block mb-2">
              <Label
                htmlFor="price"
                value={rent ? rent.title : 'Saisissez le prix'}
              />
            </div>
            <TextInput
              className=""
              id="price"
              type="number"
              value={price}
              placeholder="0000"
              onChange={(e) => setPrice(e.target.value || price)}
              required
            />
            {priceError && (
              <p className="text-xs italic text-red-500">{priceError}</p>
            )}
          </div>
          <div>
            <div className="block mb-2">
              <Label htmlFor="currencies" value="Choisir une devise" />
            </div>
            <Select
              className=""
              id="currencies"
              value={currency}
              onChange={(e) => setCurrency(e.target.value || currency)}
              required
            >
              <option value="">Choisissez une devise</option>
              {currencies?.map((currency) => (
                <option key={currency._id} value={currency._id}>
                  {currency?.name}
                </option>
              ))}
            </Select>
            {currencyError && (
              <p className="text-xs italic text-red-500">{currencyError}</p>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-md">
        <div className="block mb-2">
          <Label
            htmlFor="description"
            value="Ajouter une description de votre produit"
          />
        </div>
        <Textarea
          id="description"
          placeholder="Ajouter la description ici..."
          value={description}
          onChange={(e) => setDescription(e.target.value || description)}
          required
          rows={4}
        />
        {descriptionError && (
          <p className="text-xs italic text-red-500">{descriptionError}</p>
        )}
      </div>

      <div className="flex flex-col gap-2 md:flex md:flex-row lg:flex lg:flex-row">
        {showInputQuantity && (
          <div>
            <div className="block mb-2">
              <Label htmlFor="quantity" value="Quantité disponible" />
            </div>
            <TextInput
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
              required
            />
            {quantityError && (
              <p className="text-xs italic text-red-500">{quantityError}</p>
            )}
          </div>
        )}

        {!showWeightRange && (
          <div className="flex-grow">
            <div className="block mb-2">
              <Label htmlFor="weightRange" value="Tranche de poids" />
            </div>
            <Select
              id="weightRange"
              onChange={(e) => {
                let value = JSON.parse(e.target.value)
                setWeightRange(value)
              }}
              value={JSON.stringify(weightRange)}
              required
            >
              {weightRanges?.map((weightRange, index) => (
                <option key={index} value={JSON.stringify(weightRange)}>
                  {weightRange.valueMin >= 90
                    ? weightRange.valueMin + ' ou plus'
                    : weightRange.valueMin +
                      ' à ' +
                      weightRange.valueMax +
                      'Kg'}
                </option>
              ))}
            </Select>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <Button
          className="md:w-[12.5vw] w-[40vw] text-black"
          onClick={() => {
            setNext(false)
            if (isSuccess) {
              window.location.reload()
            }
          }}
        >
          Retour
        </Button>
        <Button
          className="md:w-[12.5vw] w-[40vw] bg-blue-500"
          onClick={handlePublish}
          disabled={
            !price ||
            // !type ||
            !currency ||
            !description ||
            (showInputQuantity && !quantity) ||
            (showInputRent && !rent) ||
            isLoading ||
            isSuccess
          }
        >
          {isLoading ? 'Publication ...' : 'Publier'}
        </Button>
      </div>
    </form>
  )
}
