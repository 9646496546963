import React, { useState } from 'react'
import UserAddress from './UserAddress'
import { useSelector, useDispatch } from 'react-redux'
import { setLocation } from '../../features/user/user'
import { ModalLocation } from './ModalLocation'

function LocationHandler() {
  let dispatch = useDispatch()
  let location = useSelector((state) => state.user.location)
  let [openMap, setOpenMap] = useState(false)

  return (
    <div>
      <div>
        <div className="flex lg:flex-row flex-col gap-5">
          <UserAddress location={location} />
          <div>
            <button
              onClick={() => setOpenMap(true)}
              className="flex items-center gap-1 px-2 py-3 lg:p-2 rounded-[0.5rem] shadow text-[12px] lg:text-[15px] bg-slate-200"
            >
              Modifier l{"'"}addresse
            </button>
          </div>
        </div>
      </div>
      <ModalLocation
        openModal={openMap}
        setOpenModal={setOpenMap}
        setLocation={setLocation}
        dispatch={dispatch}
      />
    </div>
  )
}

export default LocationHandler
