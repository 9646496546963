import React from 'react'
import StatusBadge from '../Badge'
import { useAcceptShipmentMutation } from '../../services/shipmentApi'
import { useSelector } from 'react-redux'
import BlankImg from '../../assets/blank-profile.png'
import CallUser from './CallUser'
import { formatCreatedToday } from '../../helpers/FormatCreatedToday'
import { BsCalendar3 } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import ShipmentOverview from './ShipmentOverview'
import AllReadyAcceptedModal from './AllReadyAcceptedModal'

function ShipmentItem({ shipment }) {
  const [acceptShipment, { isLoading }] = useAcceptShipmentMutation()
  const [isAccepted, setIsAccepted] = React.useState(false)
  const currentDeliver = useSelector((state) => state.user.currentDeliver)
  const navigate = useNavigate()

  const [viewDetail, setViewDetail] = React.useState(false)

  const handleAcceptShipment = async (deliver, shipmentId) => {
    await acceptShipment({ deliver, shipment: shipmentId }).then((response) => {
      if (response?.data?.message) {
        setIsAccepted(true)
      } else {
        navigate(`/shipments/${shipment?._id}`)
      }
    })
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      <div className="flex justify-between items-center mb-3">
        <h5 className="font-medium text-gray-800">
          Livraison {shipment?._id?.slice(-10)}
        </h5>
        <StatusBadge status={shipment?.status} />
      </div>

      <div className="flex items-center mb-3">
        <img
          className="rounded-full w-8 h-8 mr-3"
          src={shipment?.order?.customer?.image || BlankImg}
          alt=""
        />
        <div>
          <p className="text-gray-700">
            Destiné à{' '}
            <span className="font-medium">
              {shipment?.order?.customer?.username}
            </span>
          </p>
          <p className="text-gray-500 text-sm flex items-center gap-1">
            <BsCalendar3 />
            {formatCreatedToday(shipment?.createdAt)}
          </p>
        </div>
      </div>

      <div className="flex gap-2">
        {shipment?.status === 'pending' && (
          <button
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 transition duration-300"
            onClick={(e) => {
              e.preventDefault()
              handleAcceptShipment(currentDeliver, shipment?._id)
            }}
          >
            {isLoading ? 'Accepter...' : 'Accepter la livraison'}
          </button>
        )}

        {shipment?.status === 'accepted' && (
          <CallUser
            user={shipment?.order?.seller}
            title={'le vendeur'}
            isDeliver={true}
          />
        )}
        {shipment?.status === 'in delivery' && (
          <CallUser
            user={shipment?.order?.customer}
            title={"l'acheteur"}
            isDeliver={false}
          />
        )}

        <button
          onClick={() => navigate(`/shipments/${shipment?._id}`)}
          className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-100 transition duration-300"
        >
          Voir le detail
        </button>
      </div>

      {viewDetail && (
        <ShipmentOverview
          shipment={shipment}
          openModal={viewDetail}
          setOpenModal={setViewDetail}
        />
      )}
      {isAccepted && (
        <AllReadyAcceptedModal
          openModal={isAccepted}
          setOpenModal={setIsAccepted}
        />
      )}
    </div>
  )
}

export default ShipmentItem
