import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import Login from '../pages/authentification/Login'
import CustomerSignUp from '../pages/authentification/SignUp'
import Categories from '../pages/categories'
import Home from '../pages/Home'
import FindProduct from '../pages/Enquiries/Create'
import DetailCategory from '../pages/categories/Detail'
import Categories2 from '../pages/customers/Categories'
import AnnouncementDetail from '../pages/customers/AnnouncementDetail'
import SearchResults from '../pages/customers/SearchResults'
import UserDashboard from '../pages/UserDashboard'
import TermAndConditions from '../pages/TermAndConditions'
import MentionsLegal from '../pages/MentionsLegal'
import SellConditions from '../pages/SellConditions'
import OurMission from '../pages/OurMissions'
import OurValors from '../pages/OurValors'
import ContactUs from '../pages/ContactUs'
import OurTeam from '../pages/OurTeam'
import Forgetpassword from '../pages/resetpw/Forgetpassword'
import ResetPassword from '../pages/resetpw/ResetPassword'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../features/user/user'
import ReactGA from 'react-ga4'
import Cart from '../pages/cart/Cart'
import PublishedAnnouncements from '../pages/announcements/PublishedAnnouncements'
import CreateAnnouncement from '../pages/announcements/CreateAnnouncement'
import UpdateAnnouncement from '../pages/announcements/UpdateAnnouncement'
import Favorites from '../pages/announcements/Favorites'
import Chat from '../pages/chats'
import CreateShop from '../pages/shops/CreateShop'
import UpdateShop from '../pages/shops/UpdateShop'
import Shop from '../pages/shops/Shop'
import ShopProducts from '../pages/shops/ShopProducts'
import CreateDeliver from '../pages/delivers'
import SellerOrders from '../pages/orders/SellerOrders'
import CustomerOrders from '../pages/orders/CustomerOrders'
import PendingShipments from '../pages/shipments/PendingShipments'
import EditDeliver from '../pages/delivers/update-deliver'
import Shipment from '../pages/shipments/Shipment'
import Invoice from '../pages/payments/Invoice'
import PayProduct from '../pages/payments/PayProduct'
import Search from '../pages/customers/Search'
import UserAnnouncements from '../pages/announcements/UserAnnouncements'
import AnnouncementSellerDetail from '../pages/announcements/AnnouncementSellerDetail'

function PageViewTracker() {
  // Accéder à l'objet location
  const location = useLocation()

  // Envoyer une frappe de vue de page à chaque changement de location
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])

  // Ne pas rendre de contenu
  return null
}

export default function MainRoutes() {
  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const dispatch = useDispatch()
  dispatch(setCurrentUser(currentUser))
  // const user = useSelector((state) => state.user.currentUser)

  // Envoyer une frappe de vue de page à chaque changement de location

  return (
    <div>
      <PageViewTracker />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/home/:id" element={<Home />} />

        <Route
          exact
          path="/announcements/:id/:category"
          element={<AnnouncementDetail />}
        />
        <Route exact path="/cart/:id" element={<Cart />} />
        <Route exact path="/sign-up" element={<CustomerSignUp />} />
        <Route exact path="/sign-in" element={<Login />} />
        <Route exact path="/find-product/:id" element={<FindProduct />} />
        <Route exact path="/searching" element={<Search />} />
        <Route exact path="/categories" element={<Categories />} />
        <Route exact path="/Categories2" element={<Categories2 />} />
        <Route
          exact
          path="/announcements/category/:id"
          element={<DetailCategory />}
        />
        <Route
          exact
          path="/announcements/me/:id"
          element={<PublishedAnnouncements />}
        />
        <Route
          exact
          path="/announcements/:id"
          element={<AnnouncementSellerDetail />}
        />
        <Route
          exact
          path="/announcements/seller/:id"
          element={<UserAnnouncements />}
        />
        <Route path={`/conversations/:id`} element={<Chat />} />
        <Route
          exact
          path="/announcements/create"
          element={<CreateAnnouncement />}
        />
        <Route
          exact
          path="/announcements/edit/:id"
          element={<UpdateAnnouncement />}
        />
        <Route exact path="/announcements/liked" element={<Favorites />} />

        <Route exact path="/orders/seller/:id" element={<SellerOrders />} />
        <Route exact path="/orders/me/:id" element={<CustomerOrders />} />

        <Route exact path="/shipments/pending" element={<PendingShipments />} />
        <Route exact path="/shipments/:id" element={<Shipment />} />

        <Route exact path="/products/:id" element={<SearchResults />} />
        <Route exact path="/legal/conditions" element={<TermAndConditions />} />
        <Route
          exact
          path="/legal/confidentiality"
          element={<MentionsLegal />}
        />
        <Route
          exact
          path="/legal/sell-conditions"
          element={<SellConditions />}
        />
        <Route exact path="/us/our-team" element={<OurTeam />} />
        <Route exact path="/us/our-mission" element={<OurMission />} />
        <Route exact path="/us/our-valors" element={<OurValors />} />
        <Route exact path="/contact-us" element={<ContactUs />} />

        <Route exact path="/create-shop" element={<CreateShop />} />
        <Route exact path="/shop/update/:id" element={<UpdateShop />} />
        <Route exact path="/shop/me/:id" element={<Shop />} />
        <Route exact path="/shop/:id/products" element={<ShopProducts />} />

        <Route exact path="/Account/*" element={<UserDashboard />} />
        <Route path="auth/forget-password" element={<Forgetpassword />} />
        <Route path="auth/verify/:id/:idgenerate" element={<ResetPassword />} />

        {/* Delivery */}
        <Route exact path="/delivery/create" element={<CreateDeliver />} />
        <Route exact path="/delivery/edit/:id" element={<EditDeliver />} />

        <Route exact path="/invoice" element={<Invoice />} />
        <Route exact path="/pay" element={<PayProduct />} />
      </Routes>
    </div>
  )
}
