import axios from 'axios'
import { BASE_URL } from '../../helpers/Root'

const addPhoneNumber = async (id, phone) => {
  console.log('id : ', id)
  console.log('phone : ', phone)

  try {
    const data = await axios.post(`${BASE_URL}/api/users/add-telephone/${id}`, {
      phone: phone,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export { addPhoneNumber }
