import { Popover } from 'flowbite-react'
import {
  IoEllipsisHorizontalSharp,
  IoEllipsisVerticalSharp,
} from 'react-icons/io5'
import { IoPencil, IoEyeOutline, IoTrashOutline } from 'react-icons/io5'
import ReactGA from 'react-ga4'

const links = (navigate, announcement, setSelected, setDeleting, setOpen) => {
  return [
    {
      name: 'Modifier',
      icon: <IoPencil />,
      action: () => {
        ReactGA.event({
          category: 'Announcement',
          action: 'Want to modify',
        })
        navigate(`/announcements/edit/${announcement._id}`)
      },
    },
    {
      name: 'Detail',
      icon: <IoEyeOutline />,
      action: () => {
        ReactGA.event({
          category: 'Announcement',
          action: 'View detail',
        })
        navigate(`/announcements/${announcement._id}`)
        setSelected(announcement._id)
        setOpen(true)
      },
    },
    {
      name: 'Supprimer',
      icon: <IoTrashOutline className="text-red-500" />,
      action: () => {
        ReactGA.event({
          category: 'Announcement',
          action: 'Want to delete',
        })
        setSelected(announcement._id)
        setDeleting(true)
      },
    },
  ]
}

export default function AnnouncementPopOver({
  setDeleting,
  navigate,
  announcement,
  setSelected,
  setOpen,
}) {
  return (
    <Popover
      className="-mt-5 bg-white shadow rounded-[0.5rem] md:-ml-12 sm:-ml-5"
      aria-labelledby="profile-popover"
      content={
        <div className="w-32 p-3 flex flex-col gap-2">
          {links(navigate, announcement, setSelected, setDeleting, setOpen).map(
            (link, index) => {
              return (
                <div
                  key={index}
                  className="flex gap-1 items-center"
                  onClick={link.action}
                >
                  <span>{link.icon}</span>
                  <span>{link.name}</span>
                </div>
              )
            }
          )}
        </div>
      }
    >
      <button>
        <IoEllipsisHorizontalSharp />
        <IoEllipsisVerticalSharp className="hidden" />
      </button>
    </Popover>
  )
}
