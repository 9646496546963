import React from 'react'
import { Label, TextInput } from 'flowbite-react'
import { useDispatch, useSelector } from 'react-redux'
import { startRegisterByPhone } from '../../../../features/user/login-by-phone-number'
import { IoEnterOutline } from 'react-icons/io5'
import _ from 'lodash'

function PhoneInput() {
  const dispatch = useDispatch()
  const [phoneNumber, setPhoneNumber] = React.useState()
  const [error, setError] = React.useState(false)
  const starting = useSelector((state) => state.phoneLogin.starting)

  const startSignByPhoneNumber = (phone) => {
    if (_.isEmpty(phone)) {
      setError(true)
    } else {
      dispatch(startRegisterByPhone(phone))
    }
  }

  const handleChange = (event) => {
    let value = event.target.value
    while (value[0] === '0') {
      value = value.slice(1)
    }
    setPhoneNumber(`243${value}`)
  }
  return (
    <div className="flex flex-col gap-1 w-[100%]">
      <div className="mb-1 text-[13px] text-gray-500 block">
        <Label htmlFor="phone" value="Entrez votre numero de telephone" />
      </div>
      <div className="flex gap-2">
        <TextInput
          id="phone"
          className="w-[100%] rounded-lg"
          onChange={handleChange}
          type="number"
          placeholder="813388777"
          addon="+243"
          required
        />
      </div>
      {error && (
        <div className="text-red-500">
          Veuillez saisir votre numero de telephone
        </div>
      )}
      <div className="pt-2 w-[100%]">
        <div
          className="bg-blue-500 justify-center cursor-pointer w-[98.5%] md:w-[100%] flex items-center text-white p-2 text-center rounded-[0.5rem] "
          onClick={(e) => {
            e.preventDefault()
            startSignByPhoneNumber(phoneNumber)
          }}
        >
          <div>
            <IoEnterOutline />
          </div>

          <div className="text">
            {' '}
            {starting == true ? 'Connexion...' : 'Continuer'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhoneInput
