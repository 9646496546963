import React, { useRef, useState } from 'react'
import { useGetCategoriesQuery } from '../../services/categoryApi'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

function ScrollListCategories() {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)

  const categoriesRef = useRef(null)
  const navigate = useNavigate()

  const { data: categories, isLoading } = useGetCategoriesQuery()

  console.log(isLoading)

  //Order categories by categories which has more announcements
  let ordered_categories = _.sortBy(categories, [
    function (category) {
      return category.announcements.length
    },
  ]).reverse()

  // Fonction pour faire défiler la liste des catégories
  const scroll = (direction) => {
    const scrollAmount = direction === 'left' ? -300 : 300
    if (categoriesRef.current) {
      categoriesRef.current.scrollLeft += scrollAmount
    }
  }

  return ordered_categories?.length > 0 ? (
    <div className="flex justify-center">
      <div className=" py-5 lg:pt-3 lg:pb-3 z-40 w-full sm:w-full md:w-full lg:w-[82%] bg-white lg:shadow-md rounded-lg">
        <h2 className="pb-3 lg:pb-3  text-[#21344e] text-[16px] text-sm md:text-[18px] sm:text-md text-left pl-6 lg:pl-0 lg:text-center text-slate-600">
          Categories
        </h2>
        <div className="h-[1px] bg-[rgba(59,59,59,0.06)]"></div>

        {/* Ajout des boutons de défilement et de la liste des catégories */}
        <div className="relative flex items-center lg:pt-0">
          <button
            onClick={() => scroll('left')}
            className="absolute lg:top-[2px] left-0 z-10 hidden p-2 text-white transform bg-blue-300 rounded-full shadow-lg lg:block -translate-x-2/3 hover:bg-blue-400 focus:outline-none"
          >
            <FaChevronLeft />
          </button>
          <div
            ref={categoriesRef}
            className="flex w-full p-2 overflow-x-auto rounded-lg md:px-10 sm:gap-2 lg:gap-4 sm:p-4"
          >
            {ordered_categories?.map((category) => (
              <div
                key={category?._id}
                className={`flex-shrink-0 cursor-pointer hover:bg-blue-600 hover:text-white transition-all ease-in-out delay-200 rounded-full ${
                  selectedCategoryId === category?._id
                    ? 'bg-blue-300 text-white'
                    : 'bg-blue-100 text-gray-500'
                }`}
                onClick={() => {
                  setSelectedCategoryId(category?._id)
                  navigate(
                    `/categories?id=${category?._id}&&name=${category?.name}`
                  )
                }}
              >
                <div className="p-2 lg:py-[10px] lg:px-2 lg:text-[12.5px] font-semibold text-center border-gray-300 rounded-lg sm:p-3">
                  {category?.name}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 z-10 lg:top-[2px] hidden p-2 text-white transform bg-blue-300 rounded-full shadow-lg lg:block translate-x-2/3 hover:bg-blue-400 focus:outline-none"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center">
      <div className=" py-5 lg:pt-3 lg:pb-3 z-40 w-full sm:w-full md:w-full lg:w-[82%] bg-white lg:shadow-md rounded-lg">
        <div className="flex flex-col items-center gap-2 px-2">
          <div
            alt=""
            className="flex-shrink-0 mt-[6px] w-full lg:mt-0 h-[90px] rounded-[5px]  md:h-[115px] lg:h-[90px] hover:cursor-pointer  image animated-background"
          />
        </div>
      </div>
    </div>
  )
}

export default ScrollListCategories
