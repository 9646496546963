import React, { useState } from 'react'
import emptyImage from '../assets/emptyImage.png'
import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { FaRegHeart } from 'react-icons/fa6'
// import { AiOutlineEye } from 'react-icons/ai'
import { motion } from 'framer-motion'
import AOS from 'aos'
import 'aos/dist/aos.css'
import IsSold from '../../src/assets/vendu_1.png'
import { FaHeart } from 'react-icons/fa'
import { addLikeAnnouncement } from '../services/Announcements/add-like-announcement'

function ItemProduct({ data }) {
  const navigate = useNavigate()

  const [announcements, setAnnouncements] = useState(null)
  const slicedData = announcements?.slice(-500)?.reverse()

  useState(() => {
    setAnnouncements(data)
  }, [data])

  let generateCodeForNoUser = 'hdjdhdkkdls'

  AOS.init()

  const currentUser = useSelector((state) => state.user.currentUser)

  const handleAddSeen = async (announce) => {
    if (currentUser) {
      navigate(`/announcements/${announce?._id}/${announce?.category?._id}`)
    }
    // else {
    //   navigate(`/announcements/${announce?._id}/${announce?.category?._id}`)
    // }
  }

  const handleLikeAnnouncement = async (announcement) => {
    if (currentUser) {
      let data = await addLikeAnnouncement(currentUser?._id, announcement?._id)
      if (data?.status === 200) {
        if (announcement?.likes?.includes(currentUser?._id)) {
          // The user already liked the announcement, so we need to unlike it
          const updatedLikes = announcement?.likes.filter(
            (userId) => userId !== currentUser?._id
          )
          // Update announcement with updated likes
          setAnnouncements({ ...announcement, likes: updatedLikes })
        } else {
          // The user hasn't liked the announcement, we need to add it to likes
          const updatedLikes = [...announcement.likes, currentUser?._id]
          // Update announcement with updated likes
          setAnnouncements({ ...announcement, likes: updatedLikes })
        }
      }
    } else {
      if (announcement?.likes?.includes(generateCodeForNoUser)) {
        const updatedLikes = announcement?.likes.filter(
          (userId) => userId !== generateCodeForNoUser
        )
        setAnnouncements({ ...announcement, likes: updatedLikes })
      } else {
        const updatedLikes = [...announcement.likes, generateCodeForNoUser]
        setAnnouncements({ ...announcement, likes: updatedLikes })
      }
    }
  }

  return (
    <>
      {slicedData?.map((announce) => (
        <motion.div
          key={announce._id}
          onClick={() => handleAddSeen(announce)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="relative w-full pb-3 mb-3 transition-shadow duration-300 ease-in-out border border-gray-300 rounded-lg shadow-lg cursor-pointer hover:shadow-2xl group"
        >
          {/* Image Section */}
          <div className="relative rounded-t-lg">
            {announce?.images[0] ? (
              <img
                src={announce?.images[0]}
                alt=""
                className="object-cover w-full h-40 rounded-t-lg"
              />
            ) : (
              <img
                src={emptyImage}
                alt=""
                className="object-cover w-full h-40 rounded-t-lg"
              />
            )}

            {/* Overlay for Sold Products */}
            {announce?.isSold && (
              <img
                src={IsSold}
                alt=""
                className="absolute top-0 left-0 object-cover w-20 h-20 opacity-45"
              />
            )}

            <div className="absolute inset-0 flex items-center justify-center bg-[#0003] bg-opacity-50 rounded-t-lg"></div>

            {/* Icon Section - Visible on Hover */}
            <div className="absolute flex space-x-2 transition-opacity duration-300 opacity-0 top-2 right-2 group-hover:opacity-100">
              {/* <div className="p-2 bg-white rounded-full shadow-md">
                <FaRegHeart className="text-red-500" />
              </div> */}

              <div
                className="border-[1px] border-[#FFF5] p-1 rounded-full"
                onClick={() => handleLikeAnnouncement(announce)}
              >
                {announce?.likes?.includes(currentUser?._id) ? (
                  // <FaThumbsUp className="text-red-400 text-[27px]" />
                  // <FaHeart  />
                  // <FaRegHeart className="text-red-400 text-[27px] " />
                  <FaHeart className="text-red-400 text-[27px] " />
                ) : (
                  // <FaThumbsUp className="text-white text-[27px]" />
                  // <FaRegHeart className="text-white text-[27px]" />
                  <FaRegHeart className="text-white text-[27px]" />
                )}
              </div>

              {/* <div className="p-2 bg-white rounded-full shadow-md">
                <AiOutlineEye className="text-blue-500" />
              </div> */}
            </div>
          </div>

          {/* Product Information Section */}
          <div className="px-4 pt-1">
            <div className="mb-1 font-semibold truncate text-md">
              {announce.name}
            </div>
            <div className="flex items-center justify-between">
              <div
                className={`text-sm font-bold ${announce?.isSold ? 'text-gray-400 line-through' : 'text-gray-800'}`}
              >
                <NumericFormat
                  value={announce?.price}
                  displayType={'text'}
                  thousandSeparator=" "
                  suffix={` ${announce?.currency ? announce?.currency?.shortName : 'fc'}`}
                />
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </>
  )
}

export default ItemProduct
