import React, { useState } from 'react'
import { FaEye, FaHeart, FaRegHeart, FaStar, FaStore } from 'react-icons/fa6'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useAddLikeAnnouncementMutation } from '../services/announcementApi'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

function ProductCard({ products }) {
  const navigate = useNavigate()

  const [addLikeAnnouncement] = useAddLikeAnnouncementMutation()

  const [loadingProductId, setLoadingProductId] = useState(null) // État local pour suivre le produit cliqué

  const currentUser = useSelector((state) => state.user.currentUser)

  const handleNavigatePage = async (product) => {
    if (currentUser) {
      setLoadingProductId(product._id) // Définir l'ID du produit en cours de chargement
      try {
        await addLikeAnnouncement({
          userId: currentUser?._id,
          announcementId: product._id,
        })
      } finally {
        setLoadingProductId(null) // Réinitialiser après la fin du chargement
      }
    }
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
      {products?.map((product) => (
        <div
          key={product?._id}
          className=" flex-shrink-0 w-full border-[0.001em] pt-3 border-[#94949411]"
        >
          <div
            className={
              // cn
              ('bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-[1.02]',
              product?.isSold && 'opacity-50')
            }
          >
            <div
              // to={`/product/${product.id}`}
              className="block cursor-pointer"
              onClick={() =>
                navigate(
                  `/announcements/${product?._id}/${product?.category?._id}`
                )
              }
            >
              <div className="relative px-3 image-container">
                <img
                  src={product?.images[0]}
                  alt={product?.name}
                  className="object-cover w-full transition-transform duration-300 ease-in-out transform h-36 md:h-40 lg:44 hover:scale-105"
                />
                {product?.isNew && (
                  <span className="absolute px-2 py-1 text-xs font-semibold text-white rounded-md top-2 left-2 bg-primary-600">
                    Nouveau
                  </span>
                )}
                {product?.isSold && (
                  <span className="absolute px-2 py-1 text-xs font-semibold text-white bg-red-600 rounded-md top-2 right-4">
                    Vendu
                  </span>
                )}
              </div>
              <div className="p-4">
                <h3 className="mb-2 font-semibold text-gray-800 text-md">
                  {product?.name}
                </h3>
                <p className="mb-0 text-sm text-gray-600 line-clamp-2">
                  {product?.description}
                </p>
              </div>
            </div>

            <div className="px-4 pb-4">
              {product?.shop && (
                <Link
                  // to={`/shop/${product.shop.id}`}
                  // onClick={handleShopClick}
                  className="flex items-center mb-3 space-x-2 text-sm text-primary-600"
                >
                  <FaStore className="w-4 h-4" />
                  <span>{product?.shop?.name}</span>
                </Link>
              )}

              <div className="flex items-center justify-between mb-3">
                {product?.discountedPrice ? (
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-400 line-through">
                      {
                        // formatPrice
                        product?.price
                      }
                    </span>
                    <span className="font-bold text-primary-600">
                      {
                        // formatPrice
                        product?.discountedPrice
                      }
                    </span>
                  </div>
                ) : (
                  <span className="font-bold text-blue-400">
                    {/* {
                                // formatPrice
                                product?.price
                            } */}
                    <NumericFormat
                      value={product?.price}
                      displayType={'text'}
                      thousandSeparator=" "
                      suffix={` ${product?.currency ? product?.currency?.shortName : 'fc'}`}
                    />
                  </span>
                )}
                <div className="flex items-center space-x-2">
                  <FaStar className="w-4 h-4 text-secondary-400" />
                  <span className="text-sm text-gray-600">
                    {product?.rating}
                  </span>
                </div>
              </div>

              {product?.stock !== undefined &&
                product?.totalStock !== undefined && (
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div
                      className="bg-primary-600 h-2.5 rounded-full"
                      style={{
                        width: `${(product?.stock / product?.totalStock) * 100}%`,
                      }}
                    />
                  </div>
                )}

              <div className="flex items-center justify-between">
                <button
                  onClick={() => handleNavigatePage(product)}
                  className={
                    // cn
                    'flex items-center space-x-1 text-sm hover:text-primary-600 transition-colors'
                    // ,
                    // isLiked && 'animate-like-pop'
                  }
                >
                  {
                    <div>
                      {loadingProductId === product._id ? (
                        <AiOutlineLoading3Quarters className="w-4 h-4 text-blue-500 animate-spin" />
                      ) : product?.likes?.includes(currentUser?._id) ? (
                        <FaHeart className="w-4 h-4 text-red-400" />
                      ) : (
                        <FaRegHeart className="w-4 h-4 text-gray-700" />
                      )}
                    </div>
                    //  : <div className='items-center text-[6px] justify-center w-4 h-4 text-white bg-red-700'>
                    //   ...
                    // </div>
                  }
                </button>
                <div
                  className="flex items-center space-x-1 text-sm text-gray-600"
                  title="Voir les commentaires"
                >
                  <FaEye className="w-4 h-4" />
                  <span className="text-[11px]">{product?.seen?.length}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductCard
