export function secondsToHms(seconds) {
  seconds = Number(seconds)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  let result = ''
  if (hours > 0) {
    result += hours + (hours === 1 ? ' heure, ' : ' heures, ')
  } else if (minutes > 0) {
    result += minutes + (minutes === 1 ? ' minute' : ' minutes')
  } else {
    result += seconds + (seconds === 1 ? ' seconde' : ' secondes')
  }

  return result
}
