// import { Send } from 'lucide-react';

export default function Comments({ comments }) {
  // const [newComment, setNewComment] = useState('')

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   if (newComment.trim()) {
  //     setNewComment('')
  //   }
  // }

  return (
    <div className="rounded-xl bg-white p-8 shadow-sm">
      <h2 className="mb-6 text-2xl font-semibold text-gray-900">
        Commentaires ({comments?.length})
      </h2>

      {/* <form onSubmit={handleSubmit} className="mb-8">
        <div className="flex gap-4">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="flex-1 rounded-lg border border-gray-200 px-4 py-2 focus:border-blue-500 focus:outline-none"
          />
          <button
            type="submit"
            className="flex items-center gap-2 rounded-lg bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-600"
          >
            <Send className="h-4 w-4" />
            <span>Send</span>
          </button>
        </div>
      </form> */}

      <div className="space-y-6">
        {comments?.length > 0 &&
          comments?.map((comment) => (
            <div
              key={comment.id}
              className="border-b border-gray-100 pb-6 last:border-0"
            >
              <div className="mb-2 flex items-center justify-between">
                <span className="font-medium text-gray-900">
                  {comment?.user?.username}
                </span>
                {/* <span className="text-sm text-gray-500">
                {comment.createdAt.toLocaleDateString()}
              </span> */}
              </div>
              <p className="text-gray-600">{comment?.comment}</p>
            </div>
          ))}
        {comments.length === 0 && (
          <div className="text-center">
            <p>Pas de commentaires sur ce produit</p>
          </div>
        )}
      </div>
    </div>
  )
}
