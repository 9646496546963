import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getNotificationsChat } from '../../../services/notifications/notificationChat'
import { cleanNotificationsChat } from '../../../services/notifications/cleanNotificationChat'
import { setCounterMesssage } from '../../../features/messages/countermessage'
import NotificationIconMixTop from '../nav-bar/NotificationIconMixTop'
import { links_optionsMixTop, linksMixTop } from './linksMixTop'
import { useGetCurrentCartQuery } from '../../../services/cartApi'

function LinksOptionsMixTop() {
  const navigate = useNavigate()
  let dispatch = useDispatch()

  const messages = useSelector((state) => state.message.messages)

  const currentUser = useSelector((state) => state.user.currentUser)
  const isShowCart = useSelector((state) => state.isshowcart.isShowCart)
  const counterMessage = useSelector((state) => state.counter.value)

  const { data: cart } = useGetCurrentCartQuery(currentUser?._id)

  const [countMessage, setCountMessage] = useState(null)
  const [cleanNotificationChat, setCleanNotificationChat] = useState(null)

  useEffect(() => {
    if (currentUser) {
      getNotificationsChat(currentUser?._id, setCountMessage)
    }
  }, [currentUser])

  useEffect(() => {
    if (countMessage) {
      dispatch(setCounterMesssage(countMessage?.data?.countMessage))
    }
  }, [countMessage])

  function handleCleanNotificationChat() {
    if (currentUser?._id) {
      dispatch(setCounterMesssage(0))
      cleanNotificationsChat(
        currentUser?._id,
        setCleanNotificationChat,
        cleanNotificationChat
      )
    }
  }

  return (
    <div className="flex items-center gap-2 md:gap-5 lg:gap-5">
      {links_optionsMixTop(cart?.products)?.map((link) => {
        return (
          <div key={link?.name} className="relative mr-3">
            <div
              className="text-[16px] md:text-[23px] lg:text-[23px] lg:flex cursor-pointer hidden relative"
              onClick={() => {
                navigate(`/cart/${currentUser?._id}`)
              }}
            >
              {link.icon}
            </div>
            <div
              className="text-[23px] flex cursor-pointer lg:hidden"
              onClick={() => {
                navigate(`/cart/${currentUser?._id}`)
              }}
            >
              {link.icon}
            </div>
            {isShowCart && (
              <div
                onClick={() => {
                  navigate(`/cart/${currentUser?._id}`)
                }}
                className="w-[260px] shadow-lg absolute z-[1] bg-blue-900 -right-10 top-[43px] rounded-[5px] overflow-hidden"
              >
                <>
                  {cart?.products?.slice(0, 3).map((announcement) => (
                    <div
                      key={announcement?._id}
                      className="flex gap-2 items-center py-2 border-b border-blue-800 mx-[10px] hover:bg-blue-800 cursor-pointer transition-colors"
                    >
                      <div className="h-[42px] w-[42px] rounded-[3px] border border-yellow-500">
                        <img
                          src={announcement?.productId?.images[0]}
                          alt=""
                          className="h-full w-full object-cover rounded-[3px]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[15px] w-[185px] whitespace-nowrap overflow-hidden text-ellipsis font-medium text-white">
                          {announcement?.productId?.name}
                        </span>
                        <span className="text-[14px] text-yellow-300">
                          {announcement?.productId?.price}
                          {announcement?.productId?.currency?.shortName === 'fc'
                            ? 'fc'
                            : '$'}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
                <div
                  className="px-3 py-2 text-center text-[14px] text-yellow-300 cursor-pointer hover:text-yellow-400 transition-colors font-semibold bg-blue-700 hover:bg-blue-600 rounded-b-[5px]"
                  onClick={() => {
                    navigate(`/cart/${currentUser?._id}`)
                  }}
                >
                  Voir plus
                </div>
              </div>
            )}
          </div>
        )
      })}

      <div className="block md:block lg:block">
        {linksMixTop(messages, counterMessage)?.map((link, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleCleanNotificationChat()
                navigate(link.url)
              }}
            >
              {link.icon}
            </div>
          )
        })}
      </div>

      <NotificationIconMixTop />
    </div>
  )
}

export default LinksOptionsMixTop
