import React from 'react'
import { useGetAnnouncementQuery } from '../../services/announcementApi'
import { useParams } from 'react-router-dom'
import ProductHeader from '../../components/announcement/seller-announcementDetail/ProductHeader'
import ProductDescription from '../../components/announcement/seller-announcementDetail/ProductionDescription'
import Comments from '../../components/announcement/seller-announcementDetail/Comments'
import Nav from '../../components/layout/Nav'
import PageLoader from '../../components/PageLoader'

export default function AnnouncementSellerDetail() {
  const params = useParams()
  const { data: announcement, isLoading } = useGetAnnouncementQuery(params?.id)

  // console.log(announcement)
  // const handleAddComment = (content) => {
  //   const newComment = {
  //     id: String(product.comments.length + 1),
  //     author: 'Guest User',
  //     content,
  //     createdAt: new Date(),
  //   };

  //   setProduct((prev) => ({
  //     ...prev,
  //     comments: [...prev.comments, newComment],
  //   }));
  // };

  if (isLoading)
    return (
      <div>
        <Nav />
        <PageLoader message="Chargement de l'annonce..." />
      </div>
    )

  return (
    <div className="min-h-screen bg-gray-50">
      <Nav />
      <main className="mx-auto max-w-5xl px-4 py-12 pt-44">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <ProductHeader product={announcement} />
          </div>
          <div>
            <ProductDescription description={announcement?.description} />
            <Comments comments={announcement?.comments} />
          </div>
        </div>
      </main>
    </div>
  )
}
