import { Button, Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

export default function AllReadyAcceptedModal({ openModal, setOpenModal }) {
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400 dark:text-gray-200" />
            <h3 className="mb-5 text-[18.5px] font-normal text-gray-500">
              Cette livraison a dejà été accepté par un autre livreur
            </h3>
            <p>Veullez voir d{"'"}autres livraisons en attente</p>
            <div className="flex justify-center gap-4 mb-5">
              <Button color="gray" onClick={() => setOpenModal(false)}>
                ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
