import React, { useState } from 'react'
import { IoCalendar, IoStar, IoTicket, IoHeart } from 'react-icons/io5'
// import UserAddress from '../location/UserAddress'
import {
  useFollowShopMutation,
  // useUnsuscribeShopMutation,
} from '../../services/shopApi'
import { useSelector } from 'react-redux'
import Register from '../../pages/authentification/Register'
import RegisterModal from '../modal/RegisterModal'
import _ from 'lodash'
import moment from 'moment'

export default function ShopHeader({ shop }) {
  const currentUser = useSelector((state) => state.user.currentUser)
  const [followShop, { isLoading }] = useFollowShopMutation()
  // const [unsuscribeShop] = useUnsuscribeShopMutation()
  // const [rateShop] = useRateShopMutation()

  const [connecting, setConnecting] = useState(false)
  const [registering, setRegistering] = useState(false)

  // const [thisShop, setThisShop] = React.useState(shop)
  const [isFollowed, setIsFollowed] = React.useState(() => {
    if (currentUser && shop?.followers?.includes(currentUser?._id)) {
      return true
    } else {
      return false
    }
  })

  const rating = shop?.ratings?.length > 0 ? _.mean(shop?.ratings) : 0

  const follow = async () => {
    let data = {
      id: shop?._id,
      user: currentUser?._id,
    }
    currentUser
      ? await followShop(data).then((response) => {
          console.log('followed:', response.data.message)
          // setThisShop(response?.data?.shop)
          if (response.data.message === 'You subscribe') {
            setIsFollowed(true)
          }
          if (response.data.message === 'You unsubscribe') {
            setIsFollowed(false)
          }
        })
      : setConnecting(true)
  }

  // const stopFollow = async () => {
  //   let data = {
  //     id: shop?._id,
  //     user: currentUser?._id,
  //   }
  //   currentUser
  //     ? await unsuscribeShop(data).then((response) => {
  //         console.log('followed:', response.data)
  //         setThisShop(response?.data)
  //         setIsFollowed(false)
  //       })
  //     : setConnecting(true)
  // }

  const contact = () => {
    if (currentUser) {
      window.location.href = `tel:${shop?.phone}`
    } else {
      setConnecting(true)
    }
  }

  React.useEffect(() => {
    shop?.followers?.includes(currentUser?._id) && setIsFollowed(true)
  }, [])

  return (
    <div
      className="relative"
      style={{
        background: 'linear-gradient(to right, #E0F7FA, #F0F0F0)',
        borderRadius: '8px',
      }}
    >
      {/* Hero section avec overlay */}
      <div className="relative h-80">
        <div className="" />
        {/* <div className="absolute inset-0 bg-black/20 backdrop-blur-[2px]" /> */}
      </div>

      {/* Contenu du header */}
      <div className="absolute top-0 left-0 right-0 h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-end">
          <div className="w-full transform translate-y-1/2">
            <div className="bg-white rounded-xl shadow-xl p-6">
              <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-8">
                {/* Info du shop */}
                <div>
                  <div className="sm:flex sm:items-center gap-6">
                    <div className="flex-shrink-0">
                      <img
                        className="mx-auto h-24 w-24 rounded-xl border-4 border-white shadow-lg"
                        src={shop?.logo}
                        alt={shop?.name}
                      />
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:text-left">
                      <h1 className="text-3xl font-bold text-gray-900">
                        {shop?.name}
                      </h1>
                      <p className="mt-2 text-lg text-gray-600 max-w-xl">
                        {shop?.description}
                      </p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mt-4">
                    <button
                      onClick={contact}
                      className="btn bg-slate-200 cursor-pointer text-gray-600 hover:bg-blue-600 rounded-md px-4 py-2"
                    >
                      Contactez-nous
                    </button>
                    {isFollowed ? (
                      <button
                        onClick={follow}
                        className="btn bg-red-500 cursor-pointer text-white hover:bg-green-600 rounded-md px-4 py-2"
                      >
                        {isLoading ? 'Se desabonner...' : 'Se desabonner'}
                      </button>
                    ) : (
                      <button
                        onClick={follow}
                        className="btn bg-green-500 cursor-pointer text-white hover:bg-green-600 rounded-md px-4 py-2"
                      >
                        {isLoading ? 'Suivre...' : 'Suivre'}
                      </button>
                    )}

                    {/* <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Voir sur la carte
                        </button> */}
                  </div>
                </div>

                {/* Statistiques */}
                <div className="mt-6 sm:mt-0">
                  <div className="grid grid-cols-2 gap-4 lg:grid-cols-1">
                    {/* <div className="flex items-center gap-2">
                      <UserAddress location={shop?.location?.coordinates} />
                    </div> */}

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <IoHeart className="h-5 w-5 text-red-200" />
                      </div>
                      <span className="text-[10px] lg:text-[15px]">
                        {shop?.followers?.length} followers
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-yellow-50 rounded-lg">
                        <IoStar className="h-5 w-5 text-yellow-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        {rating} / 5.0 en moyenne{' '}
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <IoTicket className="h-5 w-5 text-blue-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        {shop?.announcements?.length} produits publiés
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="p-2 bg-green-50 rounded-lg">
                        <IoCalendar className="h-5 w-5 text-green-500" />
                      </div>
                      <span className="text-gray-600 text-[10px] lg:text-[15px]">
                        Membre depuis{' '}
                        {moment(shop?.createdAt).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {connecting && (
        <Register
          openModal={connecting}
          setOpenModal={setConnecting}
          setRegistering={setRegistering}
        />
      )}
      {registering && (
        <RegisterModal openModal={registering} setOpenModal={setRegistering} />
      )}
    </div>
  )
}
