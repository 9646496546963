import { IoHeart, IoMail, IoCart } from 'react-icons/io5'
import ImagesCarroussel from '../ImagesCarroussel'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export default function ProductHeader({ product }) {
  const navigate = useNavigate()
  return (
    <div className="mb-8">
      <div className="relative h-[350px] w-full overflow-hidden rounded-2xl">
        <ImagesCarroussel images={product?.images} />
      </div>
      <div className="mt-3">
        <div className="flex flex-col lg:flex-row items-center lg:justify-between gap-5">
          <h1 className="text-4xl font-bold text-gray-900">{product?.name}</h1>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2 text-gray-600 hover:text-red-500 transition-colors">
              <IoHeart className="h-6 w-6" />
              <span className="font-medium">{product?.likes?.length}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <IoMail className="h-6 w-6" />
              <span className="font-medium">{product?.comments?.length}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <IoCart className="h-6 w-6" />
              <span className="font-medium">0 ventes</span>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2 text-sm text-gray-500">
          <span>
            Publié le {moment(product?.createdAt).format('DD/MM/YYYY')}
          </span>
          <span>
            Dernière modification le{' '}
            {moment(product?.updatedAt).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="mt-4 flex gap-4 text-sm text-gray-500">
          <button
            className="p-5 py-2 rounded-[1rem] bg-gray-200 text-gray-600"
            onClick={() => navigate(`/announcements/edit/${product?._id}`)}
          >
            Modifier
          </button>
          <button
            className="p-5 py-2 rounded-[1rem] bg-blue-500 text-white"
            onClick={() => navigate('/announcements/create')}
          >
            Ajouter un autre produit
          </button>
        </div>
      </div>
    </div>
  )
}
