import { useEffect, useRef, useState } from 'react'
import { validatePhoneNumber } from '../../../../features/user/login-by-phone-number'
import { startRegisterByPhone } from '../../../../features/user/login-by-phone-number'
import { useDispatch, useSelector } from 'react-redux'

const OtpInput = ({ length = 5, onOtpSubmit = () => {} }) => {
  const dispatch = useDispatch()
  const phone = useSelector((state) => state.phoneLogin.phone)
  const status = useSelector((state) => state.phoneLogin.status)
  const validating = useSelector((state) => state.phoneLogin.validating)
  const currentUser = useSelector((state) => state.phoneLogin.user)
  const [otp, setOtp] = useState(new Array(length).fill(''))
  const inputRefs = useRef([])

  console.log('phone', phone)

  const verifyPhone = (e, otp, phone) => {
    e.preventDefault()
    dispatch(validatePhoneNumber({ otp: otp, phone: phone }))
  }

  const RestartSignByPhoneNumber = (phone) => {
    dispatch(startRegisterByPhone(phone))
  }

  useEffect(() => {
    if (currentUser !== null && status == 'CONNECT') {
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      window.location.reload()
    }
  }, [currentUser])

  useEffect(() => {
    inputRefs.current[0]?.focus()
  }, [])

  const updateOtp = (index, value) => {
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp]
      newOtp[index] = value
      return newOtp
    })
  }

  const handleChange = (index, e) => {
    const value = e.target.value
    if (isNaN(value)) return

    updateOtp(index, value.substring(value.length - 1))

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleClick = (index) => {
    inputRefs.current[index]?.setSelectionRange(1, 1)
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf('')]?.focus()
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  useEffect(() => {
    const combinedOtp = otp.join('')
    if (combinedOtp.length === length) {
      onOtpSubmit(combinedOtp)
      //displayOtp(combinedOtp) // Affiche le OTP
    }
  }, [otp])

  return (
    <div className="flex flex-col gap-5 w-[100%] ">
      <div className="w-[100%] ">
        <span className="text-sm flex items-center justify-center">
          Verifiez vos sms et saisissez le code envoyé
        </span>
      </div>
      <div className="-mb-2">
        <span>Entrez le code de validation</span>
      </div>
      <div className="flex gap-2">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="w-[20%] h-[8.5vh] text-center rounded-[0.25rem] border border-silver-500"
          />
        ))}
      </div>

      <button
        onClick={(e) => verifyPhone(e, otp, phone)}
        className="bg-blue-500 rounded-[0.5rem] p-2 text-white"
      >
        {validating ? 'Verifions...' : 'valider'}
      </button>

      <div className="text-[10.5px]">
        Vous n{"'"}avez pas reçu un code de confirmation par sms ?{' '}
        <span
          className="text-primary cursor-pointer text-center"
          onClick={() => RestartSignByPhoneNumber(phone)}
        >
          Renvoyer le code
        </span>
      </div>
    </div>
  )
}

export default OtpInput
