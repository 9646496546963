import React from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

function SearchIcon() {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/searching')
  }
  return (
    <div
      className="mr-[7px] mt-[2px] md:mt-0 lg:mt-0 md:mr-0 lg:mr-0 md:hidden block text-[30px]"
      onClick={handleClick}
    >
      <IoSearchOutline className="w-6 h-6 text-blue-500 md:h-6 md:w-6 lg:w-7 lg:h-7" />
    </div>
  )
}

export default SearchIcon
