import { Label, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../features/user/user'
import { addPhoneNumber } from '../../services/Users/add-phoneNumber'

function AddPhoneNumberModal({ isOpen, setIsOpen }) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const dispatch = useDispatch()

  const handleChange = (event) => {
    let value = event.target.value
    while (value[0] === '0') {
      value = value.slice(1)
    }
    setPhoneNumber(`243${value}`)
  }

  const handleAddPhoneNumber = async () => {
    const response = await addPhoneNumber(currentUser?._id, phoneNumber)
    if (response?.status === 200) {
      currentUser.phone = phoneNumber
      dispatch(setCurrentUser(currentUser))
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
    }
    setIsOpen(false) // Close the modal after adding the phone number
  }

  if (!isOpen) return null // Don't render anything if the modal is closed

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[350px]">
        {' '}
        {/* Modal content */}
        <div className="text-lg font-semibold mb-4">
          Ajouter un numéro de téléphone
        </div>{' '}
        {/* Modal title */}
        <div className="flex gap-2 flex-col">
          <div className="text-sm text-gray-600">
            <Label htmlFor="phone" value="Entrez votre numéro de téléphone" />
          </div>
          <div className="mb-4">
            {' '}
            {/* Increased margin-bottom */}
            <TextInput
              id="phone"
              type="number"
              placeholder="813388777"
              addon="+243"
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex justify-end">
          {' '}
          {/* Button container */}
          <button
            onClick={() => setIsOpen(false)}
            className="mr-2 px-4 py-2 rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium"
          >
            Annuler
          </button>
          <button
            onClick={handleAddPhoneNumber}
            className="px-4 py-2 rounded-lg bg-primary hover:bg-blue-600 text-white font-medium"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddPhoneNumberModal
