import React from 'react'
// import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useGetNotificationsQuery,
  useMarkReadedMutation,
} from '../../../services/notificationApi'
import { IoNotificationsOutline } from 'react-icons/io5'
import moment from 'moment'

export default function NotificationIcon() {
  const navigate = useNavigate()

  let currentUser = JSON.parse(localStorage.getItem('currentUser'))
  console.log('currentUser:', currentUser)
  const [isShowNotifications, setIsShowNotifications] = React.useState(false)
  const [loadingNotificationId, setLoadingNotificationId] = React.useState(null) // Track the notification being marked as read

  const { data: notifications } = useGetNotificationsQuery(currentUser?._id)
  const [markReaded] = useMarkReadedMutation()

  const markRead = async (notification) => {
    setLoadingNotificationId(notification?._id) // Set the loading ID
    await markReaded(notification?._id)
      .unwrap()
      .then(() => {
        navigate(`${notification?.url}`)
      })
      .catch(() => {
        setLoadingNotificationId(null) // Reset loading state on failure
      })
  }

  return (
    <div className="relative md:pl-2 lg:pl-2">
      <button
        onClick={() => setIsShowNotifications(!isShowNotifications)}
        className="relative text-slate-500 focus:outline-none hover:text-secondary-300"
        aria-label="Notifications"
      >
        {notifications?.length > 0 && (
          <div className="relative cursor-pointer bg-green">
            {notifications?.length > 0 && (
              <div
                className={` bg-red-500 absolute top-[-1px] left-[12px] md:left-[14px] lg:left-[14px] text-[9px] py-[1px] text-white ${notifications?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
              >
                {notifications?.length}
              </div>
            )}
          </div>
        )}
        <IoNotificationsOutline className="w-6 h-[22px] mt-[5px] md:mt-0 lg:mt-0 text-blue-500 md:w-7 md:h-7 lg:w-7 lg:h-7" />
      </button>

      {isShowNotifications && (
        <div className="absolute right-0 z-50 mt-2 overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-lg top-full w-70 max-h-80">
          {notifications?.length === 0 ? (
            <p className="p-4 text-gray-500">Aucune notification.</p>
          ) : (
            <div className="flex flex-col">
              {notifications?.map((notification) => (
                <div
                  key={notification?._id}
                  className="border-b border-gray-200 last:border-b-0"
                >
                  <div
                    onClick={() => markRead(notification)}
                    className={`p-3 text-gray-700 hover:bg-gray-100 focus:outline-none ${
                      loadingNotificationId === notification?._id
                        ? 'cursor-wait'
                        : ''
                    }`}
                  >
                    {loadingNotificationId === notification?._id ? (
                      <p className="text-sm font-medium text-blue-500">
                        Rédirection en cours...
                      </p>
                    ) : (
                      <>
                        <p className="text-sm font-medium truncate w-60">
                          {notification?.title}
                        </p>
                        <p className="text-xs text-gray-500">
                          {notification?.body}
                        </p>
                        <p className="text-xs text-gray-500">
                          {moment(notification?.createdAt).format('L')}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
