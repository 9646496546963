import React from 'react'
import Nav from '../../components/layout/Nav'
import { useParams } from 'react-router-dom'
import {
  useGetOrdersToDeliverQuery,
  useGetDeliveredOrdersQuery,
} from '../../services/orderApi'
import { useGetSellerShipmentsQuery } from '../../services/shipmentApi'
import { useDispatch } from 'react-redux'
import { setDropOffLocation } from '../../features/user/user'
import OrdersToValidate from '../../components/orders/OrdersToValidate'
import OrdersToDeliver from '../../components/orders/OrdersToDeliver'
import LottieLoader from '../../components/LottieLoader'
import indexLoader from '../../assets/sellerAnnouncementLoader.json'
import DeliveredOrders from '../../components/orders/DeliveredOrders'

const tabs = (orders, data, deliveredOrders, user, isLoading) => [
  {
    name: 'En cours',
    component: (
      <OrdersToValidate orders={orders} user={user} isLoading={isLoading} />
    ),
    active: orders !== undefined && orders?.length > 0,
  },
  {
    name: 'En livraison',
    component: (
      <OrdersToDeliver
        orders={data?.orders}
        user={user}
        isLoading={isLoading}
      />
    ),
    active: (orders === undefined || orders?.length === 0) && !isLoading,
  },
  {
    name: 'Livrés',
    component: (
      <DeliveredOrders
        orders={deliveredOrders}
        user={user}
        isLoading={isLoading}
      />
    ),
    active: false,
  },
]

export default function SellerOrders() {
  const params = useParams()
  const dispatch = useDispatch()
  let currentLocation = JSON.parse(localStorage.getItem('currentLocation'))
  const { data: orders, isLoading } = useGetOrdersToDeliverQuery(params.id)
  const { data: shipmentData } = useGetSellerShipmentsQuery(params.id)
  const { data: deliveredOrders } = useGetDeliveredOrdersQuery(params.id)

  const [activeTab, setActiveTab] = React.useState(0)

  React.useEffect(() => {
    dispatch(setDropOffLocation(currentLocation))

    // Find the first active tab on initial load
    const initialActiveTab = tabs(
      orders,
      shipmentData,
      deliveredOrders,
      params.id,
      isLoading
    ).findIndex((tab) => tab.active)
    setActiveTab(initialActiveTab !== -1 ? initialActiveTab : 0)
  }, [orders, shipmentData, deliveredOrders, isLoading, params.id])

  return (
    <div className="bg-gray-100 min-h-screen">
      <Nav />
      <div className="pt-40 lg:px-48 px-5">
        <h2 className="text-2xl font-bold mb-4">Mes Ventes et Livraisons</h2>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-64">
            <LottieLoader animation={indexLoader} />
            <p>Chargement des commandes en cours...</p>
          </div>
        ) : (
          <>
            <div className="flex lg:gap-2 gap-10 mb-6">
              {tabs(
                orders,
                shipmentData,
                deliveredOrders,
                params.id,
                isLoading
              ).map((tab, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 sm:px-6 sm:py-3 rounded-full font-medium text-[10px] lg:text-[15px] text-gray-700 focus:outline-none ${
                    activeTab === index
                      ? 'bg-blue-500 text-white shadow-md'
                      : 'bg-white shadow hover:bg-gray-100'
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.name}
                </button>
              ))}
            </div>

            <div>
              {
                tabs(
                  orders,
                  shipmentData,
                  deliveredOrders,
                  params.id,
                  isLoading
                )[activeTab].component
              }
            </div>
          </>
        )}
      </div>
    </div>
  )
}
