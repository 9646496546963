// import { FaCartArrowDown } from 'react-icons/fa'
import { FaRegMessage } from 'react-icons/fa6'
import { FiShoppingCart } from 'react-icons/fi'
// import { IoChatboxEllipses } from 'react-icons/io5'

let currentUser = JSON.parse(localStorage.getItem('currentUser'))

const links_optionsMixTop = (cart) => {
  return [
    {
      title: 'Panier',
      icon: (
        <div className="relative cursor-pointer bg-green">
          {cart?.length > 0 && (
            <div
              className={` bg-red-500 absolute top-[-1px] left-[12px] lg:top-[-3px] md:left-[14px] border-[1px] border-[#FFF8] lg:left-[10px] text-[8px]  text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}

              // className={` bg-red-500 absolute top-[-6px] left-[16px] text-[9px] py-[1px] text-white ${cart?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
            >
              {cart?.length}
            </div>
          )}
          <FiShoppingCart className="w-6 h-6 text-white lg:w-5 lg:h-4 hover:text-secondary-300" />
        </div>
      ),
      url: '/',
    },
  ]
}

const linksMixTop = (messages, counterMessage) => {
  return [
    {
      title: 'Messages',
      icon: (
        <div className="relative cursor-pointer bg-green">
          {counterMessage > 0 && (
            <div
              className={` bg-red-500 absolute top-[-1px] left-[12px] lg:top-[-3px] md:left-[14px] border-[1px] border-[#FFF8] lg:left-[10px] text-[8px]  text-white ${messages?.length > 9 ? 'px-[3px]' : 'px-[5px]'}  rounded-full`}
            >
              {counterMessage}
            </div>
          )}
          <FaRegMessage className="w-6 h-6 text-white lg:w-5 lg:h-4 hover:text-secondary-300" />
        </div>
      ),
      url: `/conversations/${currentUser?._id}`,
    },
  ]
}
export { links_optionsMixTop, linksMixTop }
