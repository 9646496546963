import React from 'react'
import OrderCustomerItem from './OrderCustomerItem'
import { BsBagX } from 'react-icons/bs'

export default function OrdersTracking({ orders }) {
  if (!orders || orders?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex flex-col items-center justify-center">
            <BsBagX className="text-gray-400 text-6xl mb-4" />
            <p className="text-[10px] lg:text-[15px] text-center text-gray-500">
              Vous n{"'"}avez pas de commande en cours!
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4 w-[100%]">
      {orders
        ?.map((order) => <OrderCustomerItem key={order?._id} order={order} />)
        .reverse()}
    </div>
  )
}
